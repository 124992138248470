import React, { useEffect } from 'react';
import GetMethod from '../../../functions/getMethod';
import { FaPlay } from "react-icons/fa";
import VideoComponent from '../../../components/VideoComponent';


export default function VideoSection() {
  const [video, setVideo] = React.useState({})
  useEffect(() => {
    const fetchData = async () => {
      let res = await GetMethod("/videos")
      if (res?.status == 200 || res?.status == 201) {
        setVideo(res?.data?.data[0])
      }
    }
    fetchData()
  }, [])





  return (
    <div id='video' className="relative md:w-[90%] w-[95%]  h-[250px]  md:h-[600px] mx-auto my-16 rounded-lg  md:mt-[250px] mt-[100px]">
      <span className='absolute  top-[-10%] right-[-20%] bg-[#039E744D]  xl:h-[427px] h-[200px] xl:w-[600px]  w-[200px]  '></span>
      <span className='absolute bottom-[-10%] left-[-20%] bg-[#039E744D]  xl:h-[427px] h-[200px] xl:w-[600px]  w-[200px]  '></span>
      <VideoComponent video_url={video?.video_link} cover_url={video?.cover_image} title={video?.title} />
    </div>
  );
}
