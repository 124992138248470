import React from 'react'
import Title from '../../components/Title'
import Section1 from './components/Section1'
import Section2 from './components/Section2'
import { useTranslation } from 'react-i18next'
import PageHeader from '../../components/PageHeader'
import Section3 from '../Home/components/Section3'
import PageTitle from '../../components/PageTitle'

export default function ContactUsPage() {
  const { t } = useTranslation()
  return (
    <>
      <PageTitle title={"اتصل بنا"} />
      <PageHeader title={"اتصل بنا"} paths={[
        {
          name: "الرئيسية",
          path: "/"
        },
        {
          name: "اتصل بنا",
          path: "/contact-us"
        }]} />

      <Section1 />
      <Section2 />
      <div className='mb-10'>
        <Section3 />
      </div>
    </>
  )
}

