import React from 'react';
import { useTranslation } from 'react-i18next';

function ChangeLanguageButton() {
  const { i18n } = useTranslation();
  const LANG = localStorage.getItem('LANGUAGE') || 'ar';
  const handleLanguageChange = (lang) => {
    i18n.changeLanguage(lang);
    document.documentElement.dir = lang == 'ar' ? 'rtl' : 'ltr';
    document.body.style.direction = lang === 'ar' ? 'rtl' : 'ltr';
    localStorage.setItem('LANGUAGE', lang);
    window.location.reload();
  };

  return (
    <>
      <button onClick={() => handleLanguageChange(LANG === 'ar' ? 'en' : 'ar')}>
        <h1 className=' font-bold'>{LANG === 'ar' ? 'English' : 'عربي'}</h1>
      </button>
    </>
  );
}

export default ChangeLanguageButton;