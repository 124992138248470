import { t } from "i18next"
import useStaticData from "../../../hooks/useStaticData"


export default function Section2() {
  const { staticData } = useStaticData()  
  return (
    <div data-aos="fade-up" data-aos-duration="1000" className="w-[90%] mx-auto  font-sans my-[120px] lg:mt-36 mt-24  "  >
      <div className="flex flex-col lg:flex-row items-center justify-between">
      <div className="w-full lg:w-1/2 ">
          <h2 data-aos="fade-right" data-aos-duration="1000" className="lg:text-5xl text-3xl font-bold mb-4">{t(staticData?.home?.section2?.title)}</h2>
          <p data-aos="fade-right" data-aos-duration="1000" className="text-gray-600 leading-relaxed lg:text-xl text-base w-[90%]">{t(staticData?.home?.section2?.paragraph)}</p>
        </div>

        <div data-aos="fade-left" data-aos-duration="1000" className=" w-full lg:w-1/2 mb-6 lg:mb-0 flex flex-col md:flex-row flex-wrap gap-2 items-center lg:justify-end justify-center mt-5">
          
          {
            staticData?.home?.section2?.list?.map((item, index) => (
              <ServiceButton key={index} icon={item?.icon} text={item?.text} />
            ))
          }
        </div>
        
      </div>
    </div>
  )
}



const ServiceButton = ({ icon, text }) => (
  <button data-aos="fade-right" data-aos-duration="1000" className=" w-full w-[300px] h-[96px] relative flex items-center  gap-2 bg-[#2AC89D] text-white p-10 rounded-lg lg:w-[48%]  mb-4 hover:bg-emerald-500 transition-colors">
    <div className="bg-[#1E463B] rounded-full p-3 h-[55px] w-[55px]">
    <img className=" w-full h-full" src={icon} alt="" />
    </div>
    <h1 className="lg:text-xl font-bold text-right">{t(text)}</h1>
    
    <img className="mx-1 absolute bottom-3 left-3" src={"/assets/icons/arrow.svg"} alt="" />
  </button>
)
