import React from 'react'
import Carousel from '../../../components/Carousel'
import { useTranslation } from 'react-i18next'
// import { t } from 'i18next'

export default function TargetSectors() {
  const { t } = useTranslation()
  const Arrows = {
    container: 'flex items-center rtl:flex-row-reverse justify-center  gap-3 absolute bottom-[-35%] transform -translate-x-1/2 left-1/2  z-[10]',
    prev: (
      <button
        type='button'
        className='w-[50px] h-[50px] rounded-full p-3 bg-[#B5F2E2] drop-shadow bg-opacity-50 hover:bg-opacity-100 transition-all flex items-center justify-center cursor-pointer'
      >
        <img className='w-full h-full' src="/assets/icons/arrow-l.svg" alt="" />
      </button>
    ),
    next: (
      <div
        className=' w-[50px] h-[50px] rounded-full p-3 bg-[#B5F2E2] drop-shadow bg-opacity-50 hover:bg-opacity-100 transition-all flex items-center justify-center cursor-pointer'
      >
        <img className='w-full h-full' src="/assets/icons/arrow-r.svg" alt="" />
      </div>
    ),
  }

  return (
    <section className=' md:mt-36 mt-24 bg-[#2AC89D26] py-[40px] '>
      <h1 className='md:text-5xl text-3xl font-bold  text-center text-primary '>{t("القطاعات المستهدفة")}</h1>

      <div className='flex items-center lg:gap-5 gap-1 mt-1 md:w-[90%] w-[95%] mx-auto h-full '>
        <Carousel customStyles={"gap-5 h-full w-full"} dots={false} carouselClass="section6" slidesPerView={4} customArrows={Arrows} breakpoints={{
          1024: {
            slidesPerView: 3
          },
          768: {
            slidesPerView: 2
          },
          700: {
            slidesPerView: 2
          },
          0: {
            slidesPerView: 1
          }
        }}>
          <Card color={"from-[#A69331]"} title="قطاع الزراعة" image="/assets/home/section6/p1.jpg" />
          <Card color={"from-[#FA8A5C]"} title="قطاع الصناعة" image="/assets/home/section6/p2.jpg" />
          <Card color={"from-secondary"} title="قطاع الصحة" image="/assets/home/section6/p3.jpg" />
          <Card color={"from-[#450BEA]"} title="قطاع التعليم" image="/assets/home/section6/p4.jpg" />
          <Card color={"from-[#EDE7FD]"} title="قطاع التجارة" image="/assets/home/section6/p7.jpg" />
          <Card color={"from-primary"} title="القطاع السكني" image="/assets/home/section6/p5.jpg" />
          <Card color={"from-[#136D35]"} title="القطاع التجاري" image="/assets/home/section6/p6.jpg" />
        </Carousel>
      </div>

    </section>
  )
}




function Card({ title, image, color }) {
  const { t } = useTranslation()

  return (
    <div className='carousel-cell md:w-[302px]  w-full  h-[280px] flex flex-col items-center justify-end mx-1 mb-1 drop-shadow group'>

      <div className={`md:w-[302px] w-full  h-[140px]   flex flex-col items-center justify-end py-4  bg-white rounded `}>

        <div className='relative  w-full h-full  p-4 group-hover:-translate-y-1 transition-all'>
          <img className='absolute transform -translate-x-1/2  bottom-[20%] left-1/2  bg-white  md:w-[280px] w-[320px]   lg:h-[187.285px] h-[200px] object-cover rounded' src={image} alt="" />
        </div>


        <h1 className='text-center md:text-2xl  text-xl font-bold my-[5px]'>{t(title)}</h1>
      </div>
    </div>
  )

}