import React from 'react'
import useStaticData from '../hooks/useStaticData'

export default function AdBanner() {
  const { staticData } = useStaticData()
  return (
    <div className=" w-full h-[500px] md:max-w-md   bg-emerald-500 rounded-lg shadow-md p-1 overflow-hidden">
      <img className='w-full h-full object-cover rounded-lg' src={staticData?.adBanner} alt="" />
    </div>
  )
}
