import React, { useState, useEffect } from 'react';
import axios from 'axios';
import Select from 'react-select';
import { useTranslation } from 'react-i18next';

const PhoneInput = ({ phoneNumber, setPhoneNumber }) => {
  const [phone, setPhone] = useState('');
  const [countries, setCountries] = useState([]);
  const [code, setCode] = useState('+20');
  const [selectedCountry, setSelectedCountry] = useState({
    "label": "مصر",
    "value": "مصر",
    "flag": "https://flagcdn.com/eg.svg"
});
  const { t } = useTranslation();


  useEffect(() => {
    const realValue = 
    setPhoneNumber(selectedCountry?.realValue + phone)
    setCode(selectedCountry?.realValue)
  }, [selectedCountry, phone])
  useEffect(() => {
    const fetchCountryCodes = async () => {
      try {
        // send query "?fields=idd,flags"
        const response = await axios.get('https://restcountries.com/v3.1/all?fields=idd,flags,region,translations');
        const data = response.data;
        // Filter out only Arab countries
        const arabCountries = data.filter(country => country.region === 'Africa' || country.region === 'Asia');
        let countriesData = arabCountries.map(country => ({
          label: country.translations?.ara.common,
          value: country.translations?.ara.common,
          flag: country.flags.svg, 
          realValue:country.idd.root + country.idd.suffixes[0]
        }));

        countriesData = countriesData.filter((country) => !country.value.includes("972"));
        setCountries(countriesData);
      } catch (error) {
        console.error('Error fetching country codes:', error);
      }
    };

    fetchCountryCodes();
  }, []);

  const handleCountryChange = (selectedOption) => {
    setSelectedCountry(selectedOption);
  };


  return (
    <div className='flex items-center md:w-[60%]xl:w-[90%] gap-2 my-2  bg-secondary bg-opacity-[9%] rounded-[15px] mx-auto'>

      <input
        required
        value={phone}
        onChange={(e) => setPhone(e.target.value)}
        className={` p-2 w-3/4 h-[50px] text-left placeholder:text-secondary focus:outline-none bg-transparent `}
        type="number"
        min={0}
        placeholder={t("ادخل رقم هاتفك")}
      />
      <input
      disabled
        value={code}
        className={` p-2 w-[100px] h-full  placeholder:text-secondary focus:outline-none  `}
        type="text"
      />
    
      <Select
        className='min-w-[200px]'
        required
        styles={{
          control: (provided) => ({
            ...provided,
            backgroundColor: 'transparent',
            border: 'none',
          })
        }}
        value={selectedCountry}
        onChange={handleCountryChange}
        options={countries}
        placeholder={t("اختر الدولة")}
        isSearchable={true}
        getOptionLabel={(option) => (
          <div className="flex items-center">
            <img src={option.flag} alt={option.label} className="w-6 h-6 mx-2 rounded-lg drop-shadow" />
            <span>{option.label}</span>
          </div>
        )}
        getOptionValue={(option) => option.value}
      />


    </div>
  );
};

export default PhoneInput;
