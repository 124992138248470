import React from 'react';
import ReactDOM from 'react-dom';
import App from './App';
import './index.css';
import './classesByTailwind.css';
import { BrowserRouter } from 'react-router-dom';
import 'animate.css';
import { I18nextProvider, initReactI18next } from 'react-i18next';
import i18n from 'i18next';
import ar from './locales/ar/ar.json';
import en from './locales/en/en.json';

const resources = {
  ar: { translation: ar },
  en: { translation: en },
};

const initializeLanguage = () => {
  let lang = localStorage.getItem('LANGUAGE') || 'ar';
  
  // Set default language to Arabic if not already set
  localStorage.setItem('LANGUAGE', lang);
  
  // Set text direction based on selected language
  document.body.style.direction = lang === 'ar' ? 'rtl' : 'ltr';
  document.documentElement.dir = lang === 'ar' ? 'rtl' : 'ltr';

  // Initialize i18n
  i18n.use(initReactI18next).init({
    resources,
    lng: lang,
    fallbackLng: 'ar',
    interpolation: { escapeValue: false },
    returnEmptyString: false,
  });

};

initializeLanguage();


const root = document.getElementById('root');
ReactDOM.createRoot(root).render(
  <React.StrictMode>
    <I18nextProvider i18n={i18n}>
      <BrowserRouter>
        <App />
      </BrowserRouter>
    </I18nextProvider>
  </React.StrictMode>
);