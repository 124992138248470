import { t } from 'i18next'
import React from 'react'
import { Link } from 'react-router-dom'

export default function Breadcrumb({color,paths=[]}) {
  return (
    <>
    {
      paths.map((path,i) => <React.Fragment key={i}>
        <Link className={`md:text-lg  ${color}`} to={path.path}>{t(path.name)}</Link>
        {i !== paths.length-1 && <span className={`mx-2  text-primary`}>//</span>}
      </React.Fragment>)
    }
    </>
  )
}
