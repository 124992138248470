import React, { useEffect, useState } from 'react'
import Title from '../../components/Title'
import { Link, useNavigate, useParams } from 'react-router-dom'
import GetMethod from '../../functions/getMethod'
import { useTranslation } from 'react-i18next'
import VideoComponent from '../../components/VideoComponent'
import Breadcrumb from '../../components/Breadcrumb'
import LatestPostsList from './components/LatestPostsList'
import AdBanner from '../../components/AdBanner'
import CategoriesList from './components/CategoriesList'
import PageTitle from '../../components/PageTitle'

export default function PostPage() {
  const { id } = useParams()
  const [post, setPost] = useState({})
  const { t } = useTranslation()
  const LANG = localStorage.getItem('LANGUAGE') || 'ar'
  useEffect(() => {
    const fetchData = async () => {
      const response = await GetMethod(`/posts/${id}`)
      if (response?.status === 200 || response?.status === 201) {
        setPost(response.data?.data)

      } else {
        setPost(null)
      }
    }
    fetchData()
  }, [id])

  const navigate = useNavigate()
  const handleCategoryClick = (categoryId) => {
    if (categoryId) {
      navigate(`/blog?category=${categoryId}`)
    }
  };
  return (
    <>

      <PageTitle title={"المدونة"} subtitle={post?.title} />

      <div className='w-full h-[350px] relative'>
        <img className='absolute  top-0 left-0 w-full h-full object-cover z-[-1]' src={post?.image ? post?.image : "/assets/services/p1.jpg"} alt="" />
        {/* layer */}
        <span className='bg-gradient-to-l from-[#09624A] via-[#09624ACC] to-transparent absolute top-0 left-0 w-full  h-full z-[-1] '></span>

        <div className='z-[2] md:w-[90%] w-[95%] mx-auto flex flex-col items-start  h-full justify-center gap-10 pt-5 '>
          <div><Breadcrumb color='text-white' paths={[
            {
              name: "الرئيسية",
              path: "/"
            },
            {
              name: "المدونة",
              path: "/blog"
            }, {
              name: post?.title,
            }]} />
          </div>
          {post?.title && <h1 className=' md:text-5xl text-3xl font-extrabold text-white'>{post?.title}</h1>}
        </div>



      </div>


      <div className='md:w-[90%] w-[95%] mx-auto   flex items-start flex-col-reverse md:flex-row gap-5 my-20'>

        <div className='  min-h-[500px] md:w-[35%] w-full mx-auto  flex flex-col items-start gap-5'>
          <CategoriesList handleCategoryClick={handleCategoryClick} currentCategory={post?.category?.name} />
          <LatestPostsList />
          <AdBanner />
        </div>

        {
          !post ?
            <h1>{t('لم يتم العثور على البيانات')}</h1>
            : (
              <div className=' w-full mx-auto'>
                <div className='relativexl:w-[90%] mx-auto'>

                  <img src={post?.image ? post?.image : "/assets/posts/p1.jpg"} className=' w-full  object-cover lg:h-[421px] h-[300px] rounded mx-auto  ' alt="" />


                  <div className='break-words my-10'>
                    <span className='bg-[#D7EFE9]  text-primary rounded-full md:p-4 p-3 min-w-[300px] my-3 text-nowrap'>{post?.category?.name}</span>
                    {post?.title && <h1 className=' font-bold  xl:text-5xl text-2xl my-10 '>{post?.title}</h1>}

                    <p className='text-xl' dangerouslySetInnerHTML={{ __html: post?.description?.replace(/\n/g, "<br>") }}></p>
                  </div>
                </div>

                <div className='w-full mt-20 mb-20 flex flex-col items-center justify-center '>
                  <Link to={`/blog`} className='bg-primary text-xl text-white font-bold w-[200px] h-[50px] flex justify-center items-center rounded'>
                    {t("باقي المقالات")}
                  </Link>
                </div>


              </div>

            )
        }

      </div>

    </>
  )
}

