import React, { useEffect, useState } from 'react'
import Carousel from '../../../components/Carousel'
import { IoStar } from 'react-icons/io5'
import { Link } from 'react-router-dom'

import GetMethod from '../../../functions/getMethod'
import { useTranslation } from 'react-i18next'

export default function ReviewSection() {
  const { t } = useTranslation()
  const [reviews, setReviews] = useState([])
  const [loading, setLoading] = useState(true)
  useEffect(() => {
    const fetchData = async () => {
      const response = await GetMethod("/reviews")
      if (response?.status == 200 || response?.status == 201) {
        setReviews(response?.data?.data)
        setLoading(false)
      }
    }
    fetchData()
  }, [])


  const Dots = {
    dotStyle:"bg-[#B5F2E2]  w-5 h-5 rounded-full",
    activeDotStyle: " relative bg-primary after:transform after:translate-x-1/2 after:-translate-y-1/2 after:absolute after:w-4 after:h-4 after:rounded-full after:bg-primary after:border-[3px] after:border-white",
  }


  return (
    <section className='  py-28 bg-[#2AC89D26] relative '>
      <span className='bg-white absolute bottom-0 left-0 w-full h-[50%]'></span>

      <div className='flex items-center xl:gap-5 gap-1 mt-10   md:w-[90%] w-[95%] mx-auto '>

        <Carousel customStyles={" h-full w-full  relative items-stretch"} autoPlay={true} carouselClass="section9" loading={loading} arrows={false} dots={true} customDots={Dots}   breakpoints={{
          1024: {
            slidesPerView: 2,
            centeredSlides:false
          },
          768: {
            slidesPerView: 2,
            centeredSlides:false
          },
          640: {
            slidesPerView: 1
          },
          0: {
            slidesPerView: 1
          }
        }}>
        {
          reviews.length > 0 &&

          (reviews?.map((review, index) => (
            <Card key={index} id={review?.id} title={review?.title} description={review?.description} name={review?.client_name} stars={review?.stars} image={review?.image_url} date={review?.date} />
          ))
          )
        }

        </Carousel>

      </div>

    </section>
  )
}



function Card({ id, stars, title, description, name, date, image }) {
  const filledStars = stars >= 0 && stars <= 5 ? stars : 0;

  // Generate an array of stars with correct classes
  const starIcons = Array.from({ length: 5 }, (_, index) => (
    <div key={index}>
      <IoStar size={25} className={index < filledStars ? 'text-[#F7CB15]' : 'text-white'} />
    </div>
  ));

  return (
    <div className=' carousel-cell  my-5   h-[300px]   w-full' >
      <div className=' w-full h-full bg-white drop-shadow-lg rounded-[5px]    z-[2] p-9  relative'>
        <div className='flex items-center justify-between w-full '>

          <div className='flex items-center  justify-center gap-2'>
            <img className='lg:w-16 w-12 lg:h-16 h-12 rounded-full bg-[#B4B4B4]' src={image ? image : "/assets/user.svg"} alt="" />
            <div className='font-bold'>
              <h1 className='lg:text-lg  text-sm'>{name}</h1>
              <p className='lg:text-lg  text-sm'>{title}</p>
            </div>

          </div>


          <svg width="60" height="60" viewBox="0 0 60 60" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M54.375 30H45V22.5C45 18.3633 48.3633 15 52.5 15H53.4375C54.9961 15 56.25 13.7461 56.25 12.1875V6.5625C56.25 5.00391 54.9961 3.75 53.4375 3.75H52.5C42.1406 3.75 33.75 12.1406 33.75 22.5V50.625C33.75 53.7305 36.2695 56.25 39.375 56.25H54.375C57.4805 56.25 60 53.7305 60 50.625V35.625C60 32.5195 57.4805 30 54.375 30ZM20.625 30H11.25V22.5C11.25 18.3633 14.6133 15 18.75 15H19.6875C21.2461 15 22.5 13.7461 22.5 12.1875V6.5625C22.5 5.00391 21.2461 3.75 19.6875 3.75H18.75C8.39062 3.75 0 12.1406 0 22.5V50.625C0 53.7305 2.51953 56.25 5.625 56.25H20.625C23.7305 56.25 26.25 53.7305 26.25 50.625V35.625C26.25 32.5195 23.7305 30 20.625 30Z" fill="#DFF7F0" />
          </svg>

        </div>

        <div className='flex items-center justify-start  rounded-full  gap-4 text-lg my-4'>
          {starIcons}
        </div>

        <div className='mb-1  w-full'>
        <p className='lg:text-base  text-sm  my-1'>{(description?.split(300).includes(' ') ? description?.split(' ').slice(0, 300).join(' ') : description?.slice(0, 300))}</p>
        </div>

        <div>

        </div>

      </div>


    </div>
  )

}
