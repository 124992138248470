import React, { useEffect, useState } from 'react';
import Title from '../../components/Title';
import { Link } from 'react-router-dom';
import { TiArrowLeftThick } from 'react-icons/ti';
import GetMethod from '../../functions/getMethod';
import { useTranslation } from 'react-i18next';
import Section4_5 from '../Home/components/Section3';
import PageHeader from '../../components/PageHeader';
import MainPagination from '../../components/Pagination';
import Skeleton from 'react-loading-skeleton'
import PageTitle from '../../components/PageTitle';



export default function ProjectsPage() {
  const [projects, setProjects] = useState([]);
  const [filteredProjects, setFilteredProjects] = useState([]);
  const [loading, setLoading] = useState(true);  // Added loading state
  const { t } = useTranslation();
  const [currentPage, setCurrentPage] = useState(1);

  const handlePageChange = (page) => {
    setCurrentPage(page);
    setLoading(true);
    window.scrollTo(0, 0);
    setTimeout(() => {
      setLoading(false);
    }, 500);
  };

  useEffect(() => {
    const fetchData = async () => {
      setLoading(true);  // Set loading true when starting the fetch
      const response = await GetMethod("/projects");

      if (response?.status === 200) {
        setProjects(response.data?.data);
      }
      setLoading(false);  // Set loading false when data is fetched
    };
    fetchData();
  }, []);

  const PAGE_SIZE = 6;
  const totalPages = Math.ceil(projects.length / PAGE_SIZE);

  useEffect(() => {
    const startIndex = (currentPage - 1) * PAGE_SIZE;
    const endIndex = startIndex + PAGE_SIZE;
    const filtered = projects.slice(startIndex, endIndex);
    setFilteredProjects(filtered);
  }, [projects, currentPage]);

  return (
    <>
    <PageTitle  title={"المشاريع"} />
      <div className='w-full'>
      <PageHeader title={"المشاريع"} paths={[
      {
        name: "الرئيسية",
        path: "/"
      },
      {
      name: "المشاريع",
      path: "/projects"
    }]} />

        <div className='lg:w-[80%] flex flex-wrap justify-center items-center  mx-auto mt-10 gap-5'>
          {loading ? (
            // Render Skeleton Loader while loading is true
            Array(6).fill(0).map((_, index) => <ProjectSkeleton key={index} />)
          ) : filteredProjects.length > 0 ? (
            filteredProjects.map((item, index) => (
              <ProjectCard key={index} id={item?.id} title={item?.name} text={item?.description} img={item?.image_url} width={"w-[300px]"} isActive={true} />
            ))
          ) : (
            <h1 className='text-2xl font-bold text-center mt-10'>{t("لا يوجد مشاريع")}</h1>
          )}
        </div>

        <MainPagination totalPages={totalPages} onPageChange={handlePageChange} />
        <div className='mb-24'>
        <Section4_5 />
        </div>
      </div>
    </>
  );
}

// Project card component
export const ProjectCard = ({ id, title,  img, width, isActive,gradient="from-[#1E293B]  to-transparent",marging="m-2" }) => {
  const { t } = useTranslation();

  return (
    <Link to={`/projects/${id}`} data-aos="fade-up" className={`relative md:w-[411px]  overflow-hidden   md:h-[411px] h-[280px] bg-secondary rounded-lg group  ${width} ${marging}`}>
      <span className={`absolute bottom-0 left-0 w-full  bg-gradient-to-t  ${gradient} group-hover:h-full h-full  transition-all ${isActive ? 'h-full' :"md:h-0" }`}></span>
      <div className='w-full h-full'><img src={img} className='w-full h-full object-cover rounded-lg' alt="" /></div>
      <div className={`group-hover:flex flex-col absolute mx-auto w-ful min-h-[30%] rounded-md rounded-r-none items-start justify-between bottom-10 text-white p-4 align-bottom ${isActive ? 'flex' : 'md:hidden'}`}>
        <h1 className='lg:text-3xl text-lg font-bold break-words'>{(title?.split(80).includes(' ') ? title?.split(' ').slice(0, 3).join(' ') : title?.slice(0, 15))}</h1>
        <>
        <Link  to={`/projects/${id}`} className='flex items-center justify-center cursor-pointer gap-2 mt-2 bg-white rounded-3xl w-[170px] md:p-4  p-2 drop-shadow-lg  arrow_btn'>
          <h1 className='text-xs md:text-base text-black font-bold'>{t("معرفة المزيد")}</h1>
            <svg width="19" height="19" viewBox="0 0 19 19" fill="#ffffff" xmlns="http://www.w3.org/2000/svg">
              <path d="M10.8857 16.3795L10.1028 17.1576C9.77137 17.487 9.237 17.4854 8.91106 17.1539L2.09758 10.3022C1.76812 9.97073 1.76975 9.43636 2.10122 9.11042L8.95644 2.29695C9.28791 1.96749 9.82229 1.96912 10.1482 2.30059L10.9263 3.08344C11.2593 3.41844 11.2506 3.96334 10.9086 4.28925L6.65993 8.31223L16.7638 8.34309C17.2314 8.34452 17.6064 8.72184 17.605 9.18941L17.6015 10.3144C17.6001 10.782 17.2228 11.157 16.7552 11.1556L6.65134 11.1247L10.8753 15.1736C11.2188 15.5016 11.2242 16.0465 10.8857 16.3795Z" fill="black" />
            </svg>
        </Link></>
      </div>
    </Link>
  );
}

// Skeleton loader component
export const ProjectSkeleton = () => {
  return (
    
    <Skeleton highlightColor='#039e74'  count={1} width={415} height={400} />
  );
};
