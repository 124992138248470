import React, { useEffect, useState } from 'react'
import Aos from 'aos';
import 'aos/dist/aos.css';
import Navbar from './components/Navbar';
import PublicRouter from './Routes/PublicRouter';
import Footer from './components/Footer';
import 'flickity/css/flickity.css';
import FloatIcons from './components/FloatIcons.jsx';
import ScrollToTopOnPageChange from './components/ScrollToTop';
import Loader from './components/Loader.jsx';
import { isBrowser } from 'react-device-detect';
import 'react-loading-skeleton/dist/skeleton.css'
import useStaticData from './hooks/useStaticData.js';
import { Helmet } from 'react-helmet';
import { t } from 'i18next';


export default function App() {
  const [loading, setLoading] = useState(true)
  const { staticData } = useStaticData()
  useEffect(() => {
    Aos.init({ duration: 1000 });

    setTimeout(() => {
      setLoading(false)
    }, 1000);
  }, [])

  useEffect(() => {

    if (isBrowser) {

      // get current device scale
      const scale = window.devicePixelRatio
      let zoomValue = 1
      if (scale >= 2) {
        zoomValue = 0.5
      } else if (scale >= 1.5) {
        zoomValue = 0.7
      } else if (scale >= 1.25) {
        zoomValue = 0.8
      }
      document.documentElement.style.setProperty('zoom', zoomValue)
    }
  }, [])
  return (
    <>
      <Helmet>
        <title>{t(staticData?.meta?.title)}</title>
        <meta name="description" content={staticData?.meta?.description} />
      </Helmet>
      {
        loading && <Loader />
      }
      <FloatIcons />
      <ScrollToTopOnPageChange />
      <Navbar />
      <PublicRouter />
      <Footer />
    </>
  )
}

