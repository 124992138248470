
import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { Link } from 'react-router-dom'
import GetMethod from '../../../functions/getMethod'
import { ServiceCard } from '../../Services/ServicesPage'

export default function ServicesSection() {
  
  const { t } = useTranslation()

  const [services, setServices] = useState([])
  useEffect(() => {
    const fetchData = async () => {
      const response = await GetMethod("/services")

      if (response?.status == 200) {
        setServices(response.data?.data)
      }
    }
    fetchData()
  }, [])
  return (
    <section className='md:mt-36 mt-24  md:w-[90%] w-[95%] mx-auto'>
      <h1 className='md:text-5xl text-3xl font-bold  text-center '>{t("الخدمات التي نقدمها")}</h1>


      <div className='flex  flex-col md:flex-row  items-center justify-between mt-10 gap-3 w-full mx-auto '>
        {
          services[0] && services[0]?.id != undefined ?
            services?.slice(0, 3).map((item, index) => (
              <ServiceCard key={index} link={`/services/${item?.id}`} title={item?.name} description={item?.description} image={item?.image_url} />
            )) :
            <>
              <ServiceCard link={"/services/1"} title={"استصلاح الأراضي الصحراوية في واحة سيوة"} description={"تعتبر واحة سيوة إحدي أهم المراكز السياحية في مصر سواء كانت السياحة ترفيهية أو إستشفائية..."} image="/assets/home/section5/p1.jpg" />
              <ServiceCard link={"/services/1"} title={"وحدة الامداد بالطاقة أثناء انقطاع الكهرباء"} description={"يعمل جهاز إمداد الطاقة اللامنقطعة علي تخزين الطاقة الكهربية عن طريق الشحن التلقائي..."} image="/assets/home/section5/p2.jpg" />
              <ServiceCard link={"/services/1"} title={"أنظمة المنزل الذكي"} description={"يُعَدُّ نظام المنزل الذكي أحد تلك التطبيقات البارزة، حيث يمكنه التحكم الآلي بوظائف المنزل..."} image="/assets/home/section5/p3.jpg" />
            </>
        }
      </div>

      <Link to={'/services'} className='flex items-center justify-between group bg-primary rounded-full px-4 p-2 w-[220px]   text-white lg:my-10 my-5 mx-auto'>
          <h1 className='font-bold'>{t("المزيد من الخدمات")}</h1>
          <svg className='group-hover:rotate-[-45deg] transition-all' width="50" height="50" viewBox="0 0 55 55" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M19.6517 39.4837H17.3232C16.3373 39.4837 15.5402 38.6866 15.5455 37.7059L15.5402 17.3218C15.5402 16.3359 16.3373 15.5388 17.318 15.544L37.7074 15.544C38.6933 15.544 39.4904 16.3411 39.4852 17.3218L39.4852 19.6502C39.4852 20.6466 38.6618 21.449 37.6654 21.428L25.3259 21.1291L40.3977 36.2009C41.0951 36.8983 41.0951 38.0206 40.3977 38.7181L38.7195 40.3962C38.022 41.0937 36.8998 41.0937 36.2023 40.3962L21.1305 25.3244L21.4294 37.664C21.4557 38.6656 20.6533 39.489 19.6517 39.4837Z" fill="white" />
          </svg>

        </Link>



    </section>
  )
}

