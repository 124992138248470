import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { isBrowser, isMobile } from 'react-device-detect';
import GetMethod from '../functions/getMethod';

export default function FloatIcons() {
  const [socialLinks, setSocialLinks] = useState(null);
  const [isScrolled, setIsScrolled] = useState(false);
  const LANG = localStorage.getItem('LANGUAGE') || 'ar';
  const staticSocialIcons = [
    {
      name: "whatsapp",
      icon: '/assets/floatIcons/whatsapp.svg',
      link:"https://wa.me/+201008309836",
      
    }
  ];
  const socialIcons = {
    "youtube": '/assets/floatIcons/youtube.svg',
    "facebook": '/assets/floatIcons/facebook.svg',
    "whatsapp": '/assets/floatIcons/whatsapp.svg',
    "instagram": '/assets/floatIcons/instagram.svg',
    "twitter": '/assets/floatIcons/twitter.svg',
    "linkedin": '/assets/floatIcons/linkedin.svg'
  };


  useEffect(() => {
    
    const fetchData = async () => {
      const response = await GetMethod('/social-links');
      if (response?.status === 200 || response?.status === 201) {
        setSocialLinks(response?.data?.social_links[0]);
      }
    };

    fetchData();
  
  }, []);

  useEffect(() => {
    const handleScroll = () => {
      const { scrollHeight, scrollTop, clientHeight } = document.documentElement;
      const isAtBottom =  (scrollHeight - scrollTop ) === clientHeight;

      const footer = document.getElementById('footer');
      const isAtFooter = footer && (scrollTop + clientHeight >= footer.offsetTop);

      setIsScrolled(scrollTop > 10  && !isAtBottom && !isAtFooter);
    };
    
    
    window.addEventListener('scroll', handleScroll);
  
    // Remove event listener on component unmount
    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  
  }, []);


  const directionClass = LANG === 'ar' ? 'right-6' : 'left-6';
  const fixedClass = isScrolled ? 'fixed' : 'hidden';

  return (
    <section className={`${fixedClass} transition-all ease-in duration-300 bottom-6 ${directionClass} z-10`}>
      <div className='flex flex-col items-center gap-2 '>
        {socialLinks ?  (
          <>
            {socialLinks?.whatsapp &&   <SocialIcon name="whatsapp" icons={socialIcons} link={socialLinks?.whatsapp} />}
          </>
        ) : (
          staticSocialIcons?.map((icon, index) => (
            <SocialIcon key={index} name={icon.name} icons={socialIcons} link={icon.link} />
          ))
        )}
      </div>
    </section>
  );
}

const SocialIcon = ({ name, link, icons }) => {
  const icon = icons[name];
  return (
    <Link className='md:w-[52px] w-[32px] md:h-[52px] h-[32px] p-[1px]' to={link} target='_blank'>
      <img className='w-full h-full drop-shadow-md rounded object-contain cursor-pointer' src={icon} alt={name} />
    </Link>
  );
};

