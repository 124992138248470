import React, { useRef, useEffect, useState } from 'react';
// Import Swiper React components and modules
import { Swiper, SwiperSlide } from 'swiper/react';
import { Navigation, Pagination, Autoplay } from 'swiper/modules';

// Import Swiper styles
import 'swiper/css';
import 'swiper/css/navigation';
import 'swiper/css/pagination';

const Carousel = ({
  customStyles,
  children,
  carouselClass,
  dots = true,
  autoPlay = true,
  loop = true,
  loading = false,
  slidesPerView = 1,
  arrows = true,
  customArrows,
  customDots,
  breakpoints = {},
}) => {
  const swiperRef = useRef(null);
  const prevRef = useRef(null); // Ref for custom prev button
  const nextRef = useRef(null); // Ref for custom next button
  const [activeIndex, setActiveIndex] = useState(0); // Track the active slide index

  useEffect(() => {
    if (loading) {
      return;
    }

    if (swiperRef.current && swiperRef.current.swiper) {
      swiperRef.current.swiper.params.navigation.prevEl = prevRef.current;
      swiperRef.current.swiper.params.navigation.nextEl = nextRef.current;
      swiperRef.current.swiper.navigation.update(); // Update Swiper to use custom navigation elements

      // Listen for slide change to update the active index
      swiperRef.current.swiper.on('slideChange', () => {
        setActiveIndex(swiperRef.current.swiper.activeIndex);
      });
    }

    return () => {
      // Clean up Swiper instance when component unmounts
      if (swiperRef.current && swiperRef.current.swiper) {
        swiperRef.current.swiper.off('slideChange'); // Remove event listener
        swiperRef.current.swiper.destroy(true, true);
      }
    };
  }, [loading]);

  return (
    <div className={`focus:outline-none ${customStyles} ${carouselClass} relative`}>
      <Swiper
        ref={swiperRef}
        modules={[Navigation, Pagination, Autoplay]} // Enable Swiper modules
        pagination={false} // Disable default pagination
        navigation={arrows ? customArrows ? { nextEl: nextRef.current, prevEl: prevRef.current } : true : false} // Enable custom arrows
        autoplay={autoPlay ? { delay: 3000, disableOnInteraction: false } : false} // AutoPlay settings
        loop={loop} // Enable loop
        spaceBetween={30} // Space between slides
        slidesPerView={slidesPerView} // Set the number of slides visible per view
        centeredSlides={true} // Center slides
        breakpoints={breakpoints}
      >
        {React.Children.map(children, (child, index) => (
          <SwiperSlide key={index} className="flex justify-center items-center">
            {child}
          </SwiperSlide>
        ))}
      </Swiper>

      {/* Render custom dots if provided */}
      {dots && (customDots && (
        <div className="flex justify-center items-center gap-2 ">
          {React.Children.map(children, (_, index) => (
            <button
              key={index}
              className={` ${customDots?.dotStyle}  ${activeIndex === index && customDots?.activeDotStyle}`}
              onClick={() => {
                if (swiperRef.current && swiperRef.current.swiper) {
                  swiperRef.current.swiper.slideTo(index);
                }
              }}
            />
          ))}
        </div>
      ))}

      {/* Render custom arrows if provided */}
      {arrows && customArrows && (
        <div className={customArrows?.container}>
          <div ref={prevRef} className="">
            {customArrows.prev}
          </div>
          <div ref={nextRef} className="">            
            {customArrows.next}
          </div>
        </div>
      )}
    </div>
  );
};

export default Carousel;
