import { useTranslation } from 'react-i18next';
import React from 'react';
import ContactForm from './ContactForm';
import { Link } from 'react-router-dom';
import SocialIcons from '../../../components/SocialMedia';

function Section1() {
  const { t } = useTranslation();

  return (
    <>
      <div className='md:w-[90%] w-[95%] mx-auto flex flex-col-reverse md:flex-row justify-between gap-4 items-center my-10'>

        <section className='md:w-[60%] w-full md:h-[500px] h-[300px] flex flex-col items-center justify-end pb-20 ' style={{ backgroundImage: `url("/assets/ContactUs/i1.png")`, backgroundSize: "cover", backgroundPosition: "center" }}>

          <div className='bg-primary border border-white p-4 px-8 rounded-full min-w-[40%]  mx-auto  gap-2 text-white flex items-center justify-between '>

            <h1 className='md:text-3xl text-base  font-extrabold'>{t("تابعنا")}</h1>
          <SocialIcons containerClass="flex gap-3 "  iconClass={"scale-100 hover:scale-125 w-auto text-white"} />
          </div>


        </section>

        <section className='md:w-[40%] w-full min-h-[500px]'>
          <ContactForm />
        </section>


      </div>
    </>
  );
}


export default Section1;



