import { useEffect, useState } from "react";
import { FaChevronLeft, FaChevronRight } from "react-icons/fa"
import { Link } from "react-router-dom";
import GetMethod from "../../../functions/getMethod";
import { t } from "i18next";

function CategoriesList({  handleCategoryClick, currentCategory }) {
  const [categories, setCategories] = useState([]);
  useEffect(() => {
    const fetchData = async () => {
      const response = await GetMethod("/categories");

      if (response?.status === 200) {
        setCategories(response.data?.data);
      }

    };
    fetchData();
  }, []);
  
  
  return (
    <div className="w-full lg:max-w-md mx-auto bg-white  shadow-md border-2 border-primary p-4 rounded-lg ">
      <h2 className="text-lg font-bold p-4 border-b text-center">{t("قائمة الاقسام")}</h2>
      <ul className=" flex flex-col gap-5 p-2 lg:p-0">
        <button
          onClick={() => handleCategoryClick(null)}
          className={`  text-nowrap lg:w-full flex items-center justify-between p-4 bg-[#D7EFE9] hover:bg-[#039E74] hover:text-white cursor-pointer transition-all rounded-[50px]   ${currentCategory === null ? "bg-primary text-white" : ""}`}
        >
          <span className="w-full text-right">{t("جميع المقالات")}</span>
          <FaChevronLeft className="h-5 w-5 ltr:scale-x-[-1]" />
        </button>
        {categories.map((cat, index) => (
          <div
            onClick={() => handleCategoryClick(cat.name)}
            key={index}
            className={`flex items-center text-nowrap justify-between p-4  lg:w-full bg-[#D7EFE9] hover:bg-[#039E74] hover:text-white cursor-pointer transition-all rounded-[50px]  ${currentCategory === cat.name ? "bg-primary text-white" : ""}`}
          >
            <span className="w-full text-right">{cat.name.split(" ").length > 3 ? cat.name.split(" ").slice(0, 3).join(" ") + "..." : cat.name}</span>
            <FaChevronLeft className="h-5 w-5 ltr:scale-x-[-1]" />
          </div>
        ))}
      </ul>
    </div>
  )
}

export default CategoriesList