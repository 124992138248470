import { useEffect, useState } from "react";
import { FaChevronLeft, FaChevronRight } from "react-icons/fa"
import { Link } from "react-router-dom";
import GetMethod from "../../../functions/getMethod";
import { t } from "i18next";

function ServicesList() {
  const [services, setServices] = useState([]);

  useEffect(() => {
    const fetchData = async () => {
      const response = await GetMethod("/services");

      if (response?.status === 200 || response?.status === 201) {
        setServices(response.data?.data);
      }
    };
    fetchData();
  }, []);

  return (
    <div className="w-full md:max-w-md  bg-white   overflow-hidden border-2 border-primary p-4 rounded-lg">
      <h2 className="text-lg font-bold p-4 border-b text-center">{t("قائمة الخدمات")}</h2>
      <ul className="divide-y">
        {services.map((service, index) => (
          <Link
            to={`/services/${service.id}`}
            key={index}
            className={`flex items-center justify-between p-4 bg-[#D7EFE9] hover:bg-[#039E74] hover:text-white cursor-pointer transition-all rounded-[50px] my-5 `}
          >
            <span>{service.name}</span>
            <FaChevronLeft className="h-5 w-5 ltr:scale-x-[-1]" />
          </Link>
        ))}
      </ul>
    </div>
  )
}

export default ServicesList