import React from 'react'
import { Link } from 'react-router-dom'
import Breadcrumb from './Breadcrumb'
import { t } from 'i18next'

export default function PageHeader({title,paths=[]}) {
  return (
    <div className="bg-[#e6f2f2]  flex flex-col items-center justify-center text-right ">
      <div className="w-full flex flex-col items-center justify-center p-4 py-20 ">
        <h1 className="md:text-[55px] sm:text-[28px] text-[25px] font-extrabold mb-4 text-black">{t(title)}</h1>
        <div className="text-sm text-gray-600">
          <Breadcrumb paths={paths} />
        </div>
      </div>
    </div>
  )
}
