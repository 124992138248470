
import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { Link } from 'react-router-dom'
import GetMethod from '../../../functions/getMethod'
import { PostCard } from '../../Blog/components/Section2'

export default function LatestPosts() {
  const { t } = useTranslation()

  const [posts, setPosts] = useState([])

  useEffect(() => {
    const getData = async () => {
      const response = await GetMethod("/posts");
      if (response?.status === 200 || response?.status === 201) {
        setPosts(response.data?.data.slice(0, 3));

      }
    }


    getData()
  }, [])
  return (
    <section className=' md:w-[90%] w-[95%] mx-auto md:my-36 my-24'>
      <h1 className='text-4xl font-bold  text-center '>{t("اخر المقالات")}</h1>

      {
        posts.length > 0 ? (
          <div className='flex  flex-col md:flex-row items-center justify-evenly  mx-auto mt-10 gap-3'>
            {
              posts?.map((post, index) => (
                <PostCard key={index} link={`/post/${post.id}`} description={post.description} image={post.image} title={post.title} />
              ))
            }
          </div>
        )
          :
          (<h1 className='text-3xl text-center  font-bold my-10 mt-14'>{t("لا يوجد مقالات")}</h1>)
      }

      <div className='w-full mt-10 flex flex-col items-center justify-center '>
        <Link to={`/blog`} className='bg-primary text-center text-xl text-white font-bold w-[200px] h-[50px] flex justify-around  group items-center rounded'>
          {t("باقي المدونات")}
          <span className='group-hover:translate-x-[-6px] transition-all'>
          <svg width="19" height="19" viewBox="0 0 19 19" fill="#ffffff" xmlns="http://www.w3.org/2000/svg">
            <path d="M10.8857 16.3795L10.1028 17.1576C9.77137 17.487 9.237 17.4854 8.91106 17.1539L2.09758 10.3022C1.76812 9.97073 1.76975 9.43636 2.10122 9.11042L8.95644 2.29695C9.28791 1.96749 9.82229 1.96912 10.1482 2.30059L10.9263 3.08344C11.2593 3.41844 11.2506 3.96334 10.9086 4.28925L6.65993 8.31223L16.7638 8.34309C17.2314 8.34452 17.6064 8.72184 17.605 9.18941L17.6015 10.3144C17.6001 10.782 17.2228 11.157 16.7552 11.1556L6.65134 11.1247L10.8753 15.1736C11.2188 15.5016 11.2242 16.0465 10.8857 16.3795Z" fill="white" />
          </svg>
          </span>
        </Link>
      </div>

    </section>
  )
}


