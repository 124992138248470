import React, { useEffect, useState } from 'react'
import Title from '../../components/Title'
import { Link, useParams } from 'react-router-dom'
import GetMethod from '../../functions/getMethod'
import { useTranslation } from 'react-i18next'
import Breadcrumb from '../../components/Breadcrumb'
import ConatctUsSection from '../Home/components/ConatctUsSection'
import ServicesList from './components/ServicesList'
import AdBanner from '../../components/AdBanner'
import PageTitle from '../../components/PageTitle'

export default function ServiceDetailsPage() {
  const { id } = useParams()
  const [service, setService] = useState({})
  const { t } = useTranslation()
  const LANG = localStorage.getItem('LANGUAGE') || 'ar'
  useEffect(() => {
    const fetchData = async () => {
      const response = await GetMethod(`/services/${id}`)
      if (response?.status === 200 || response?.status === 201) {
        setService(response.data?.data)
      } else {
        setService(null)
      }
    }
    fetchData()
  }, [id])
  return (
    <>
    <PageTitle  title={"الخدمات"} subtitle={service?.name}  />

      <div className='w-full h-[350px] relative'>
        <img className='absolute  top-0 left-0 w-full h-full object-cover z-[-1]' src={service?.image_url ? service?.image_url : "/assets/Services/p1.jpg"} alt="" />
        {/* layer */}
        <span className='bg-gradient-to-l from-[#09624A] via-[#09624ACC] to-transparent absolute top-0 left-0 w-full  h-full z-[-1] '></span>

        <div className='z-[2] md:w-[90%] w-[95%] mx-auto flex flex-col items-start  h-full justify-center gap-10 pt-5 '>
          <div><Breadcrumb color='text-white' paths={[
            {
              name: "الرئيسية",
              path: "/"
            },
            {
              name: "الخدمات",
              path: "/services"
            }, {
              name: service?.name,
            }]} />
          </div>
          {service?.name && <h1 className=' md:text-5xl text-3xl font-extrabold text-white'>{service?.name}</h1>}
        </div>



      </div>


      <div className=' md:w-[90%] w-[95%] mx-auto   flex flex-col-reverse md:flex-row items-start justify-start  mt-20'>

        <div className=' flex flex-col justify-start items-start   min-h-[500px] md:w-[35%] mt-10 md:mt-0  gap-5'>
          <ServicesList />
          <AdBanner />
        </div>

        {
          !service ?
            <h1>{t('لم يتم العثور على البيانات')}</h1>
            : (
              <div className='md:w-[75%]  mx-auto h-full'>
                  <img src={service?.image_url ? service?.image_url : "/assets/Services/p1.jpg"} className=' w-full  object-cover lg:h-[421px] h-[300px] rounded mx-auto  ' alt="" />


                  <div className='break-words'>
                    {service?.name && <h1 className=' font-bold  xl:text-5xl text-2xl my-10'>{service?.name}</h1>}

                    <p  className='text-xl' dangerouslySetInnerHTML={{ __html: service?.description?.replace(/\n/g, "<br>") }}></p>
                  </div>



              </div>

            )
        }

      </div>
      



      <ConatctUsSection />
    </>
  )
}

// const Triangle = () => {
//   return (
//     <div className='w-full h-full  bg-red-400' style={{ borderLeft: '50px solid transparent', borderRight: '50px solid transparent', borderBottom: '100px solid #F7CB15' }}></div>
//   );
// };

