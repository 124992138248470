import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import GetMethod from '../../../functions/getMethod'
import { Link } from 'react-router-dom'
import useStaticData from '../../../hooks/useStaticData'

export default function Section3() {
  const { t } = useTranslation()
  const [contactData, setContactData] = useState([]);
  const { staticData } = useStaticData()

  useEffect(() => {
    const fetchData = async () => {
      const response = await GetMethod("/contact-data")
      if (response?.status === 200) {
        setContactData(response.data?.data)
      }
    }
    fetchData()
  }, [])
  return (
    <section className='bg-tertiary md:w-[90%] w-[95%]  rounded-lg drop-shadow-md mx-auto  md:h-[350px] min-h-[300px] lg:p-10 p-3 flex flex-col md:flex-row items-center justify-between text-white  md:mt-36 mt-24'  >

      <div data-aos="fade-up" data-aos-duration="1000" className='text-nowrap'>
        <h1 className='lg:text-[88px] md:text-[60px] text-[40px] font-extrabold' >{t(staticData?.home?.section3?.title)}</h1>
        <p className=' lg:text-[48px] md:text-[25px] text-[20px] text-nowrap text-[#7B4E2F] font-bold'>{t(staticData?.home?.section3?.paragraph)}</p>
      </div>

      
              <span className='md:h-[60%] h-1  md:w-1 w-full bg-[#394853] '></span>

      <div className='flex flex-col gap-4 md:flex-row  items-center justify-center   xl:gap-10 xl:px-5   py-5 px-2 ' data-aos="fade-up" data-aos-duration="1000">
        <img className='xl:w-[80px] w-[40px] xl:h-[80px] h-[40px] bg-white rounded-full p-3' src="/assets/icons/phone.svg" alt="" />


        <div>
          <p className='text-xl text-center md:text-right'>{t("تحتاج للمساعدة ؟")}</p>
          <div className='grid grid-cols-2 gap-1 items-center justify-center mt-2 md:mt-0'>
            {contactData.length > 0 && (
              <>
                {contactData[0].phone_numbers.slice(0, 4)?.map((phoneNumber, index) => (
                  <Link key={index} dir='ltr' className=' border border-[#CE7134] rounded-lg p-1  xl:text-base text-sm w-max ' to={`tel://${phoneNumber}`}>{phoneNumber}</Link>
                ))}
              </>
            )}
          </div>
        </div>


      </div>


      <span className='md:h-[60%] h-1 w-[90%] md:w-1 bg-[#394853] '></span>

      <a href={staticData?.home?.section3?.link} className='flex items-center justify-center cursor-pointer gap-2 mt-2  bg-[#CE7134] bg-opacity-80 rounded-3xl xl:w-[200px] w-[160px] p-3  ' data-aos="fade-up" data-aos-duration="1000">
        <h1 className='text-white font-bold xl:text-xl text-sm'>{t("تواصل معنا")}</h1>
        <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path d="M13.5785 20.1509L12.6078 21.1157C12.1968 21.5242 11.5342 21.5222 11.13 21.1112L2.68127 12.615C2.27274 12.204 2.27477 11.5414 2.68578 11.1372L11.1863 2.68853C11.5973 2.28 12.2599 2.28202 12.6641 2.69304L13.6289 3.66377C14.0418 4.07918 14.031 4.75485 13.6069 5.15898L8.33858 10.1475L20.8674 10.1857C21.4472 10.1875 21.9122 10.6554 21.9104 11.2352L21.9062 12.6302C21.9044 13.21 21.4365 13.675 20.8567 13.6732L8.32793 13.635L13.5657 18.6555C13.9916 19.0623 13.9983 19.738 13.5785 20.1509Z" fill="#FF8F44" />
        </svg>


      </a>

    </section>
  )
}
