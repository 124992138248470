import { t } from "i18next";
import { Link } from "react-router-dom";
import ContactForm from "../../ContactUs/components/ContactForm";
import useStaticData from "../../../hooks/useStaticData";

export default function ConatctUsSection() {
  const { staticData } = useStaticData()
  return (
    <div className="flex flex-col lg:flex-row-reverse relative items-center justify-around  bg-slate-800 text-white overflow-hidden bg-cover bg-center bg-blend-overlay bg-opacity-50 lg:mt-36 mt-24 min-h-[673px]" style={{ backgroundImage: "url('/assets/home/section5/p2.jpg?height=1080&width=1920')" }}>
      <span className='absolute bottom-0 left-0  w-full  transition-all h-full bg-black bg-opacity-50 rounded-lg z-[0]'></span>


      <div className="xl:min-w-[600px]  lg:p-8 pt-8 z-[2]" >
        <ContactForm />
      </div>


      <div className="lg:w-1/2 p-8 flex flex-col z-[1]  ">
        <h1 className="lg:text-5xl text-3xl font-extrabold mb-4">{t(staticData?.home?.conatctUsSection?.title)}</h1>
        <p className="mb-6 lg:text-lg text-sm ">
          {t(staticData?.home?.conatctUsSection?.paragraph)}
        </p>
        <Link to={`/projects`} className='flex items-center justify-center cursor-pointer gap-2 mt-2  bg-primary rounded-3xl w-[200px] h-[50px] p-2 drop-shadow-lg arrow_btn'>
          <h1 className='text-white font-bold'>{t("تصفح مشاريعنا")}</h1>
          <svg className="ltr:scale-x-[-1]" width="19" height="19" viewBox="0 0 19 19" fill="#ffffff" xmlns="http://www.w3.org/2000/svg">
            <path d="M10.8857 16.3795L10.1028 17.1576C9.77137 17.487 9.237 17.4854 8.91106 17.1539L2.09758 10.3022C1.76812 9.97073 1.76975 9.43636 2.10122 9.11042L8.95644 2.29695C9.28791 1.96749 9.82229 1.96912 10.1482 2.30059L10.9263 3.08344C11.2593 3.41844 11.2506 3.96334 10.9086 4.28925L6.65993 8.31223L16.7638 8.34309C17.2314 8.34452 17.6064 8.72184 17.605 9.18941L17.6015 10.3144C17.6001 10.782 17.2228 11.157 16.7552 11.1556L6.65134 11.1247L10.8753 15.1736C11.2188 15.5016 11.2242 16.0465 10.8857 16.3795Z" fill="white" />
          </svg>

        </Link>
      </div>
    </div>
  )
}

