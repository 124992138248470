import React, { useState, useRef, useEffect } from 'react';
import { FaPlay } from 'react-icons/fa';
import { IoCloseSharp } from 'react-icons/io5';

export default function VideoComponent({ video_url, cover_url, title }) {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const modalContentRef = useRef(null);

  function openModal() {
    setIsModalOpen(true);
  }

  function closeModal() {
    setIsModalOpen(false);
  }

  function handleClickOutside(event) {
    if (isModalOpen && modalContentRef.current && !modalContentRef.current.contains(event.target)) {
      closeModal();
    }
  }

  useEffect(() => {
    if (isModalOpen) {
      document.addEventListener('mousedown', handleClickOutside);
    } else {
      document.removeEventListener('mousedown', handleClickOutside);
    }
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [isModalOpen]);

  return (
    <div className="relative w-full h-full">
      {/* Cover image with play button */}
      {!isModalOpen && (cover_url || title) && (
        <div className='absolute top-0 left-0 right-0 bottom-0 flex items-center justify-center md:w-[90%] w-[95%]   mx-auto h-[100%] bg-white text-white'>
          <div className='w-full h-full relative'>
            <img className='absolute w-full h-full object-cover rounded' src={cover_url} alt="" />
            <div className="absolute inset-0 flex items-center justify-center text-white">
              <div className='flex flex-col items-center justify-center'>
                {title && (
                  <h2 className="lg:text-2xl text-black font-bold text-center z-1 bg-white p-2 rounded">{title}</h2>
                )}
                <button onClick={openModal} className='duration-300 group-hover:bg-primary md:p-2 order-1 rounded font-bold text-xl text-white md:mt-12 scale-50 md:scale-100'>
                  <svg width={100} height={100} viewBox="0 0 100 100" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <rect width={100} height={100} rx={50} fill="#B5F2E2" fillOpacity="0.5" />
                    <rect x="7.49902" y="7.5" width={85} height={85} rx="42.5" fill="white" />
                    <path d="M68.1549 46.7703L40.656 30.5131C38.4217 29.1928 35 30.474 35 33.7395V66.246C35 69.1756 38.1796 70.9411 40.656 69.4725L68.1549 53.2231C70.6079 51.7779 70.6157 48.2155 68.1549 46.7703Z" fill="#039E74" />
                  </svg>
                </button>
              </div>
            </div>
          </div>
        </div>
      )}

      {/* Modal with video iframe */}
      {isModalOpen && (
        <div className="fixed inset-0 flex items-center justify-center bg-black bg-opacity-75 z-50">
          {/* Modal Content */}
          <div className="relative md:w-[80%] w-[90%] md:h-[600px] h-[300px]" ref={modalContentRef}>
            <button onClick={closeModal} className="absolute top-1 right-1 text-white text-xl bg-primary p-5 rounded-full">
              <IoCloseSharp />
            </button>
            <iframe
              className="w-full h-full"
              src={video_url?.replace(/\/view\?usp=drive_link/, '/preview')}
              title="Video player"
              allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
              allowFullScreen
            ></iframe>
          </div>
        </div>
      )}
    </div>
  );
}
