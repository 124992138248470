import React, { useEffect, useState } from 'react';
import Title from './../../components/Title';
import { Link } from 'react-router-dom';
import GetMethod from '../../functions/getMethod';
import PageHeader from '../../components/PageHeader';
import { t } from 'i18next';
import Section4_5 from '../Home/components/Section3';
import MainPagination from '../../components/Pagination';
import Skeleton from 'react-loading-skeleton';
import PageTitle from '../../components/PageTitle';

export default function ServicesPage() {
  const [services, setServices] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [filteredServices, setFilteredServices] = useState([]);
  const [loading, setLoading] = useState(false);
  

  useEffect(() => {
    const fetchData = async () => {
      setLoading(true);
      const response = await GetMethod("/services");

      if (response?.status === 200 || response?.status === 201) {
        setServices(response.data?.data);

        setLoading(false);
      }
    };
    fetchData();
  }, []);

  useEffect(() => {
    const filtered = currentPage === 1 ? services.slice(0, 3) : services.slice((currentPage - 1) * 3, currentPage * 3);
    setFilteredServices(filtered);
  }, [services, currentPage]);

  const handlePageChange = (page) => {
    setLoading(true);
    window.scrollTo({ top: 0, behavior: "smooth" });
    setCurrentPage(page);
    setTimeout(() => {
      setLoading(false);
    }, 500);
  };

  const PAGE_SIZE = 3;
  const totalPages = Math.ceil(services?.length / PAGE_SIZE);

  return (
    <>
    <PageTitle  title={"الخدمات"} />
      <div>
        <PageHeader title={"خدماتنا"} paths={[
          {
            name: "الرئيسية",
            path: "/"
          },
          {
            name: "خدماتنا",
            path: "/services"
          }]} />
        <div className='md:w-[90%] w-[95%] mx-auto  flex flex-col md:flex-row  justify-between items-center  gap-10 my-20'>
          { loading ?  (Array(3).fill(0).map((_, index) => <ServiceSkeleton key={index} />)): filteredServices.map((service, index) => (
            <ServiceCard key={index} title={service?.name} description={service?.description} image={service?.image_url} link={"/services/" + service?.id} />
          ))}
        </div>
      </div>
      <MainPagination
        totalPages={totalPages}
        onPageChange={handlePageChange}
        />

      <div className='mb-10'>
      <Section4_5 />
      </div>
    </>
  );
}

export const ServiceCard = ({ title, description, image, link }) => {
  return (
    <div className='md:w-[400px] sm:w-[350px] relative  md:h-[456px] h-[360px] rounded   bg-secondary group flex flex-col  justify-evenly gap-6   p-[25px]'>

      <Link to={link} className=' w-full  mx-auto h-[50%] rounded  overflow-hidden '>
        <img className=' h-full w-full object-cover drop-shadow rounded hover:scale-[1.1] transition-all' src={image} alt="" />
      </Link>

        <div className='flex flex-col gap-2'>
        <Link to={link} className='lg:text-[28px]  font-bold group-hover:text-primary transition-all text-white'>{(title?.split(50).includes(' ') ? title?.split(' ').slice(0, 6).join(' ') : title?.slice(0, 40))}</Link>
        <p className=' lg:text-base  text-xs break-words w-full text-white font-light' dangerouslySetInnerHTML={{ __html: (description?.split(80).includes(' ') ? description?.split(' ').slice(0, 6).join(' ') : description?.slice(0,80)) }} ></p>

        </div>


        <Link to={link} className='flex items-center justify-center cursor-pointer gap-2  bg-primary rounded-3xl w-[150px] p-2  text-white'>
          <h1 className='text-white font-bold'>{t("معرفة المزيد")}</h1>
          <svg className='ltr:scale-x-[-1] group-hover:translate-x-[-9px] transition-all' width="19" height="19" viewBox="0 0 19 19" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M10.8857 16.3795L10.1028 17.1576C9.77137 17.487 9.237 17.4854 8.91106 17.1539L2.09758 10.3022C1.76812 9.97073 1.76975 9.43636 2.10122 9.11042L8.95644 2.29695C9.28791 1.96749 9.82229 1.96912 10.1482 2.30059L10.9263 3.08344C11.2593 3.41844 11.2506 3.96334 10.9086 4.28925L6.65993 8.31223L16.7638 8.34309C17.2314 8.34452 17.6064 8.72184 17.605 9.18941L17.6015 10.3144C17.6001 10.782 17.2228 11.157 16.7552 11.1556L6.65134 11.1247L10.8753 15.1736C11.2188 15.5016 11.2242 16.0465 10.8857 16.3795Z" fill="black" />
          </svg>

        </Link>


    </div>
  )
};
// Skeleton loader component
export const ServiceSkeleton = () => {
  return (
    
    <Skeleton  highlightColor='#039e74' count={1} width={400} height={456} />
  );
};
