import React from 'react'
import Hero from './components/Hero'
import VideoSection from './components/VideoSection'
import ProjectsSection7 from './components/ProjectsSection7';
import ServicesSection from './components/ServicesSection';
import ConatctUsSection from './components/ConatctUsSection';
import Section1 from './components/Section1';
import Section2 from './components/Section2';
import Section3 from './components/Section3';
import TargetSectors from './components/TargetSectors';
import ReviewSection from './components/ReviewSection';
import LatestPosts from './components/LatestPosts';
import PageTitle from './../../components/PageTitle';

export default function HomePage() {

  return (
    <main className=''>
      <PageTitle  title={"الرئيسية"} />
      <Hero />
      <Section1 />
      <ServicesSection />
      <Section2 />
      <Section3 />
      <TargetSectors />
      <ReviewSection />
      <ProjectsSection7 />
      <VideoSection />
      <ConatctUsSection />
      <LatestPosts />
    </main>
  )
}









