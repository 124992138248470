import React, { useEffect, useRef, useState } from 'react';
import { Link, NavLink, useLocation } from 'react-router-dom';
import { CiLocationOn } from 'react-icons/ci';
import { useTranslation } from 'react-i18next';
import { isBrowser } from 'react-device-detect';
import Profile from './Profile';
import AuthModal from './AuthModal';
import { IoCloseOutline, IoMenuOutline } from 'react-icons/io5';
import ChangeLanguageButton from './ChangeLanguageButton';
import SocialIcons from './SocialMedia';
import GetMethod from '../functions/getMethod';

const Navbar = () => {
  const [isScrolled, setIsScrolled] = useState(false);
  const [isProfileOpen, setIsProfileOpen] = useState(false);
  const [isAuthModalOpen, setIsAuthModalOpen] = useState(false);
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const { t } = useTranslation();
  const menuRef = useRef(null);

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (menuRef.current && !menuRef.current.contains(event.target)) {
        setIsMenuOpen(false);
      }
    };

    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [menuRef]);

  useEffect(() => {
    const handleScroll = () => {
      const scrollTop = window.scrollY;
      setIsScrolled(scrollTop > 250);
    };

    window.addEventListener('scroll', handleScroll);
    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  const openProfile = () => {
    setIsMenuOpen(false);
    const user = JSON.parse(localStorage.getItem("user"));
    user ? setIsProfileOpen(true) : setIsAuthModalOpen(true);
  };

  const Links = [
    { title: "الرئيسية", url: "/" },
    { title: "من نحن", url: "/about-us" },
    { title: "خدماتنا", url: "/services" },
    { title: "طلب سعر", url: "/request-price" },
    { title: "المشاريع", url: "/projects" },
    { title: "المدونة", url: "/blog" },
    { title: "اتصل بنا", url: "/contact-us" },
  ];


  // conatc data
  const [adresses, setAdresses] = useState([]);
  const [phoneNumber, setPhoneNumber] = useState('');

  useEffect(() => {
    const fetchData = async () => {
      const response = await GetMethod("/contact-data")
      if (response?.status === 200) {
        // get the last element of the array
        setPhoneNumber(response.data?.data[0].phone_numbers[0])
        setAdresses(response.data?.data[0].addresses)

      }
    }
    fetchData()
  }, [])

  return (
    <>
      <nav className={`md:min-h-[100px] bg-white left-0 w-full z-[99] transition-all duration-500 drop-shadow fixed top-0 ${isScrolled ? 'translate-y-[-45px]' : ''}`}>
        <section className={` hidden xl:flex w-full h-[45px] bg-primary  items-center justify-between md:px-5 px-1 gap-1 lg:gap-10 transition-all ${isScrolled ? 'hidden' : ''}`}>

          <div dir='ltr' className='flex items-center  gap-4 h-full '>

            <Link to={"https://maps.app.goo.gl/8z4YrjoBNm9f6jQN8"} className='flex items-center justify-center gap-2 text-white'>
              <p className='text-[14px]'>{t(adresses[0])}</p>
              <svg width={16} height={16} viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                <g clipPath="url(#clip0_86_7621)">
                  <path d="M13.891 0.110078L0.897922 6.10695C-0.601141 6.80664 -0.101453 9.00539 1.49761 9.00539H6.9948V14.5026C6.9948 16.1016 9.19355 16.6016 9.89324 15.1023L15.8901 2.10914C16.3898 0.909453 15.0904 -0.389922 13.891 0.110078Z" fill="#B5F2E2" />
                </g>
                <defs>
                  <clipPath id="clip0_86_7621">
                    <rect width={16} height={16} fill="white" />
                  </clipPath>
                </defs>
              </svg>
            </Link>


            <span className='h-full w-[1px] mx-1 bg-[#B5F2E2] bg-opacity-10'></span>

            <Link to={`mailto:info@gommacompany.com`} className='flex items-center justify-center gap-2 text-white'>
              <p className='text-[14px]'>info@gommacompany.com</p>
              <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M15.6969 5.9625C15.8187 5.86563 16 5.95625 16 6.10938V12.5C16 13.3281 15.3281 14 14.5 14H1.5C0.671875 14 0 13.3281 0 12.5V6.1125C0 5.95625 0.178125 5.86875 0.303125 5.96562C1.00312 6.50937 1.93125 7.2 5.11875 9.51562C5.77813 9.99687 6.89062 11.0094 8 11.0031C9.11563 11.0125 10.25 9.97813 10.8844 9.51562C14.0719 7.2 14.9969 6.50625 15.6969 5.9625ZM8 10C8.725 10.0125 9.76875 9.0875 10.2937 8.70625C14.4406 5.69688 14.7562 5.43437 15.7125 4.68437C15.8937 4.54375 16 4.325 16 4.09375V3.5C16 2.67188 15.3281 2 14.5 2H1.5C0.671875 2 0 2.67188 0 3.5V4.09375C0 4.325 0.10625 4.54062 0.2875 4.68437C1.24375 5.43125 1.55938 5.69688 5.70625 8.70625C6.23125 9.0875 7.275 10.0125 8 10Z" fill="#B5F2E2" />
              </svg>
            </Link>

            <span className='h-full w-[1px] mx-1 bg-[#B5F2E2] bg-opacity-10'></span>

            <Link to={`tel:${phoneNumber.split("+")[0]}`} className='flex items-center justify-center gap-2 text-white'>
              <h1 className='text-[14px]'>{phoneNumber}</h1>
              <svg width={16} height={16} viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                <g clipPath="url(#clip0_86_7617)">
                  <path d="M15.5434 11.3065L12.0434 9.80645C11.8939 9.74273 11.7277 9.72931 11.5699 9.7682C11.4121 9.80708 11.2712 9.89618 11.1684 10.0221L9.61844 11.9158C7.18586 10.7689 5.22819 8.81122 4.08125 6.37864L5.975 4.82864C6.10115 4.72604 6.19044 4.58513 6.22934 4.42725C6.26825 4.26937 6.25466 4.10311 6.19062 3.95364L4.69062 0.453642C4.62035 0.29252 4.49605 0.160969 4.33917 0.0816733C4.18229 0.00237739 4.00266 -0.0196931 3.83125 0.0192674L0.58125 0.769267C0.41599 0.807429 0.268545 0.90048 0.16298 1.03323C0.0574141 1.16598 -3.80691e-05 1.3306 1.89256e-08 1.5002C1.89256e-08 9.51583 6.49687 16.0002 14.5 16.0002C14.6697 16.0003 14.8343 15.9429 14.9672 15.8373C15.1 15.7318 15.1931 15.5843 15.2312 15.419L15.9812 12.169C16.02 11.9967 15.9974 11.8164 15.9175 11.6589C15.8376 11.5015 15.7053 11.3769 15.5434 11.3065Z" fill="#B5F2E2" />
                </g>
                <defs>
                  <clipPath id="clip0_86_7617">
                    <rect width={16} height={16} fill="white" />
                  </clipPath>
                </defs>
              </svg>
            </Link>



          </div>

          <SocialIcons containerClass={"divide-x divide-[#15a77f] "} />
        </section>

        <section className={`flex items-center relative justify-around lg:w-[90%]  p-3 mx-auto  h-max transition-all ` }>
          <Link to={'/'}><img className='md:w-[17rem] w-[10rem] object-cover' src="/assets/logo.svg" alt="" /></Link>

          <div className='items-center justify-center xl:gap-4 gap-2 lg:w-full hidden lg:flex'>
            {Links.map((link, index) => (
              <NavLink className={`text-secondary lg:text-lg text-xs font-bold hover:text-primary transition-all`} to={link.url} key={index}>
                {t(link.title)}
              </NavLink>
            ))}
          </div>


          <section className='flex items-center justify-center gap-5'>
            <ChangeLanguageButton />

            <Link to={"/request-price"} className=' hidden bg-[#FF8F44] rounded-[50px] xl:h-[45px] xl:w-[155px] w-[100px] md:flex items-center justify-between px-3 p-1 group cursor-pointer'>
              <h1 className='text-white font-bold xl:text-base text-xs text-center'>
                {t("طلب سعر")}
              </h1>

              <div className='group-hover:translate-x-1 translate-x-[-12px] transition-all hidden xl:block'>
                <svg width={45} height={45} viewBox="0 0 45 45" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <rect width={45} height={45} rx="22.5" fill="#9D592B" />
                  <g clipPath="url(#clip0_86_1700)">
                    <path d="M18.8605 28.2616H17.7568C17.2894 28.2616 16.9116 27.8837 16.914 27.4189L16.9116 17.7561C16.9116 17.2887 17.2894 16.9109 17.7543 16.9134L27.4195 16.9134C27.8869 16.9134 28.2648 17.2912 28.2623 17.7561L28.2623 18.8598C28.2623 19.3322 27.872 19.7125 27.3997 19.7026L21.5503 19.5609L28.6948 26.7054C29.0255 27.036 29.0255 27.568 28.6948 27.8987L27.8993 28.6942C27.5687 29.0248 27.0367 29.0248 26.7061 28.6942L19.5615 21.5496L19.7032 27.399C19.7157 27.8738 19.3353 28.2641 18.8605 28.2616Z" fill="#FFBF95" />
                  </g>
                  <defs>
                    <clipPath id="clip0_86_1700">
                      <rect width={18} height={18} fill="white" transform="translate(22.7285 10) rotate(45)" />
                    </clipPath>
                  </defs>
                </svg>

              </div>


            </Link>

            {/* menu button */}
            <div className='lg:hidden cursor-pointer' onClick={() => setIsMenuOpen(!isMenuOpen)}>
              <IoMenuOutline className='text-3xl text-secondary' />
            </div>

          </section>


        </section>
      </nav>
      
      <div className=' h-[117px] w-full'></div>

      {isMenuOpen && (
        <>
          <div className='fixed w-full h-full top-0 left-0 bg-black opacity-50 z-[99] lg:hidden'></div>
          <div ref={menuRef} className='fixed lg:hidden w-[90%] bg-white h-full top-0 left-0 z-[99] drop-shadow-lg animate__animated animate__slideInLeft animate__faster flex flex-col items-center justify-between'>
            <div className='flex items-center justify-between p-10 mt-5 w-full '>
              <img className='xl:w-32 w-40 object-cover' src="/assets/logo.svg" alt="" />
              <IoCloseOutline className='text-3xl cursor-pointer' onClick={() => setIsMenuOpen(false)} />
            </div>
            <div className='items-right justify-start p-10  gap-6 w-full flex-col flex'>
              {Links.map((link, index) => (
                <NavLink className={`text-secondary text-xl font-bold hover:text-primary transition-all`} onClick={() => setIsMenuOpen(false)} to={link.url} key={index}>
                  {t(link.title)}
                </NavLink>
              ))}
            </div>
            <div className='bg-primary rounded w-full '>
            </div>
              <div className='border-t-2 w-full   py-5  bg-primary text-white text-center'>
              <SocialIcons containerClass="text-black  h-10 " iconClass={"text-white hover:scale-110 transition-all"} />  </div>
          </div>
        </>
      )}

    </>
  );
};

export default Navbar;
