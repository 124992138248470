import React, { useState } from 'react'
import postMethod from '../../../functions/postMethod';
import Swal from 'sweetalert2';
import { t } from 'i18next';
import useStaticData from '../../../hooks/useStaticData';
export default function ContactForm() {
  const [formData, setFormData] = useState({});
  const { staticData } = useStaticData()

  const Send = async (e) => {
    e.preventDefault()
    Swal.fire({
      title: 'جاري التحميل...',
      html: 'يرجى الانتظار بينما يتم معالجة الطلب .',
      allowOutsideClick: false,
      showCloseButton: false,
      showConfirmButton: false,
      onBeforeOpen: () => {
        Swal.showLoading();
      }
    })

    if (!formData.name || !formData.phone || !formData.email) {
      Swal.fire({
        icon: 'error',
        title: 'يرجي ادخال جميع البيانات',
      })
      return
    }
    const res = await postMethod("/contacts", formData);

    if (res?.status === 200 || res?.status === 201) {
      Swal.fire({
        icon: 'success',
        title: 'تم الارسال بنجاح',
      })

      setFormData({});
    } else {
      Swal.fire({
        icon: 'error',
        title: 'لقد حدث خطأ',
      })
    }
  };

  const handleChange = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };

  return (
    <div className="w-full   min-h-[508px] z-[1]">
      <div className="bg-white md:rounded-[23px] rounded-[10px] md:p-[35px] p-[15px]  border-2 h-full">
        <h2 className="text-3xl font-extrabold mb-6 text-right text-secondary">{t("تواصل معنا")}</h2>
        <form onSubmit={Send} className="space-y-4 min-h-full flex flex-col  items-end gap-5">
          <div className="grid sm:grid-cols-2 gap-4  align-middle w-full ">
            <input onChange={handleChange} name="name" type="text" placeholder={t("اكتب اسمك")} className="w-full p-3  border-2 border-[#D6D6D6] rounded-[5px] placeholder:text-[#9D9D9D] text-right text-black focus:outline-primary bg-[#D6D6D6] bg-opacity-10" />
            <input onChange={handleChange} name='phone' type="tel" placeholder={t("اكتب رقم هاتفك")} className="w-full p-3  border-2 border-[#D6D6D6] rounded-[5px] placeholder:text-[#9D9D9D] text-right text-black focus:outline-primary bg-[#D6D6D6] bg-opacity-10" />
            <input onChange={handleChange} name='email' type="email" placeholder={t("اكتب بريدك الإلكتروني")} className="w-full p-3  border-2 border-[#D6D6D6] rounded-[5px] placeholder:text-[#9D9D9D] text-right text-black focus:outline-primary bg-[#D6D6D6] bg-opacity-10" />
            <select onChange={handleChange} name='service' className="w-full p-3   border-2 border-[#D6D6D6] rounded-[5px] placeholder:text-[#9D9D9D] text-right text-black focus:outline-primary  bg-[#D6D6D6] bg-opacity-10">
              <option value="" disabled selected>{t("إختر نوع الخدمة")}</option>
              {
                staticData?.form?.services?.map((service, index) => (
                  <option key={index} value={service}>{service}</option>
                ))
              }
            </select>
          </div>
          <textarea placeholder={t("اكتب رسالتك هنا")} className="w-full md:h-[174.99px] h-[100px] p-3  border-2 border-[#D6D6D6] rounded-[5px] placeholder:text-[#9D9D9D] text-right text-black focus:outline-primary resize-none bg-[#D6D6D6] bg-opacity-10"></textarea>
          <button type="submit" className=" bg-primary  flex items-center justify-between p-2 px-5 float-left hover:bg-opacity-80  text-white font-bold  rounded-full  w-[156px] transition duration-300  group">
            <span>{t("إرسال")}</span>
            <svg className='group-hover:-translate-x-2 transition duration-300' width="36" height="36" viewBox="0 0 36 36" fill="none" xmlns="http://www.w3.org/2000/svg">
              <g clip-path="url(#clip0_103_5161)">
                <path d="M10.1649 24.7504L9.31759 25.5976C8.95884 25.9564 8.37873 25.9564 8.02379 25.5976L0.604513 18.1822C0.245761 17.8234 0.245761 17.2433 0.604513 16.8884L8.02379 9.46909C8.38255 9.11034 8.96265 9.11034 9.31759 9.46909L10.1649 10.3164C10.5274 10.6789 10.5198 11.2705 10.1496 11.6254L5.5507 16.0068H16.5193C17.0269 16.0068 17.4353 16.4151 17.4353 16.9227V18.144C17.4353 18.6516 17.0269 19.06 16.5193 19.06H5.5507L10.1496 23.4413C10.5236 23.7962 10.5312 24.3878 10.1649 24.7504Z" fill="white" />
              </g>
              <defs>
                <clipPath id="clip0_103_5161">
                  <rect width="25" height="25" fill="white" transform="translate(17.6776) rotate(45)" />
                </clipPath>
              </defs>
            </svg>

          </button>
        </form>
      </div>
    </div>
  )
}
