import React from 'react';
import { useTranslation } from 'react-i18next';
import useStaticData from '../../../hooks/useStaticData';

export default function Section1() {
  const { t } = useTranslation();
  const { staticData } = useStaticData()
  return (
    <section className='relative md:w-[90%] w-[95%] mx-auto z-[1] flex flex-col md:flex-row  md:mt-36 mt-24 md:gap-2 gap-10'>

      <div className='flex flex-col gap-7 md:w-[50%]'>
        <h1 data-aoss="fade-up" className='xl:text-5xl  text-3xl font-bold  text-secondary mt-2'>{t(staticData?.home?.section1?.title)}</h1>
        <p data-aoss="fade-up" data-aoss-delay="200" className=' md:w-[90%] w-full xl:text-xl text-base text-gray-500'>{t(staticData?.home?.section1?.paragraph)}</p>


      {
        staticData?.home?.section1?.list?.map((item, index) => (
          <div data-aoss="fade-up" data-aoss-delay="300" className='flex items-center  gap-3 ' key={index}>
            <span className='bg-primary  w-[50px] h-[50px]  flex items-center justify-center rounded p-2 text-white'>0{index + 1}</span>
            <h1 className='md:text-xl text-primary font-bold'>{t(item)}</h1>
          </div>
        ))
      }
      

      </div>



      <div className='w-full md:w-[50%]  md:h-[437px] h-[300px] '>
        <img data-aoss="fade-up" data-aoss-delay="400" className=' w-full h-full object-cover   rounded-xl  mx-auto' src={staticData?.home?.section1?.img} alt="" />
      </div>

    </section>
  );
}
