
import React, { useEffect, useState } from 'react'
import { TiArrowLeftThick } from 'react-icons/ti'
import { Link } from 'react-router-dom'
import GetMethod from '../../../functions/getMethod'
import { useTranslation } from 'react-i18next'
import { ProjectCard } from '../../Projects/ProjectsPage'

export default function ProjectsSection7() {
  const { t } = useTranslation()
  const [projects, setProjects] = useState([])
  const [isActive, setIsActive] = useState(0)
  useEffect(() => {
    const fetchData = async () => {
      const response = await GetMethod("/projects")

      if (response?.status === 200 || response?.status === 201) {
        const proj = response.data?.data?.slice(0, 4)
        setProjects(proj)
      }
    }
    fetchData()
  }, [])

  useEffect(() => {
    const interval = setInterval(() => {
      setIsActive((prev) => (prev + 1) % projects.length)
    }, 3000)

    return () => clearInterval(interval)
  }, [projects])

  return (
    <section className=' mt-20 flex flex-wrap md:flex-nowrap justify-center   md:w-[90%] w-[95%] mx-auto '>

      <div className='flex flex-col h-full w-full md:w-auto justify-between  '>
        <h1 className='md:text-5xl text-3xl font-extrabold  '>{t("مشاريعنا")}</h1>
        <p className='md:text-2xl text-base my-9'>{t("أخر المشاريع المُنفذة من خلال الشركة")}</p>
        <ProjectCard marging='md:m-2 m-0' id={projects[0]?.id} title={projects[0]?.name} text={projects[0]?.description} img={projects[0]?.image_url}  gradient='from-[#039E74]  to-transparent' isActive={isActive == 4 && true} />
      </div>

      <div className=' xl:flex flex-col gap-8 hidden'>
        <ProjectCard id={projects[1]?.id} title={projects[1]?.name} text={projects[1]?.description} img={projects[1]?.image_url}  gradient='from-[#039E74]  to-transparent' isActive={isActive == 1 && true} />
        <ProjectCard id={projects[2]?.id} title={projects[2]?.name} text={projects[2]?.description} img={projects[2]?.image_url}  gradient='from-[#039E74]  to-transparent' isActive={isActive == 2 && true} />
      </div>

      <div className='  mt-5 md:mt-0 w-full   md:w-max flex flex-col justify-center md:items-center'>
        <ProjectCard marging='md:m-2 m-0' id={projects[3]?.id} title={projects[3]?.name} text={projects[3]?.description} img={projects[3]?.image_url}  gradient='from-[#039E74]  to-transparent' isActive={isActive == 3 && true} />
        <Link to={'/projects'} className='flex items-center justify-between group bg-primary rounded-full px-7 p-3 w-[250px]   text-white lg:my-10 my-5 mx-auto'>
          <h1 className='font-bold'>{t("المزيد من المشاريع")}</h1>
          <svg className='group-hover:rotate-[-45deg] transition-all' width="50" height="50" viewBox="0 0 55 55" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M19.6517 39.4837H17.3232C16.3373 39.4837 15.5402 38.6866 15.5455 37.7059L15.5402 17.3218C15.5402 16.3359 16.3373 15.5388 17.318 15.544L37.7074 15.544C38.6933 15.544 39.4904 16.3411 39.4852 17.3218L39.4852 19.6502C39.4852 20.6466 38.6618 21.449 37.6654 21.428L25.3259 21.1291L40.3977 36.2009C41.0951 36.8983 41.0951 38.0206 40.3977 38.7181L38.7195 40.3962C38.022 41.0937 36.8998 41.0937 36.2023 40.3962L21.1305 25.3244L21.4294 37.664C21.4557 38.6656 20.6533 39.489 19.6517 39.4837Z" fill="white" />
          </svg>

        </Link>
      </div>

    </section>
  )
}

