import React , { useEffect , useState } from 'react'
import Title from './../../components/Title';
import GetMethod from '../../functions/getMethod';
import PageTitle from '../../components/PageTitle';
function MoreInfo() {
  const [contactData , setContactData] = useState(0)
  const contacts = [
    { link: "tel://01008309836/", title: '+201008309836' },
    { link: "tel://01050677804/", title: '+201050677804' },
    { link: "tel://01050677803/", title: '+201050677803' },
    { link: "tel://01050677802/", title: '+201050677802' },
    { link: "tel://01050677801/", title: '+201050677801' },
  ];
  useEffect(() => {
    const fetchData = async () => {
      const response = await GetMethod("/contact-data")
      if (response?.status === 200) {
        setContactData(response.data?.data)
      }
    }
    fetchData()
  }, [])
  return (
    <section className='my-10'>
      <PageTitle  title={"تنويه"} />
      
      <div>
        <Title title={"تنويه"} center={true} />
      </div>

      <div className='w-[90%] text-start mx-auto py-10'>
        <h1 className='w-[98%] text-2xl font-semibold '>
        يرجي العلم بأن الشركة غير مسئولة عن أي تعامل مع أي شخص يدعي الوساطة أو يدعي تمثيلنا، كما تخلي الشركة مسؤليتها من التعامل مع أي جهة تدعي الوساطة أو تدعي تمثيلنا، والشركة مسئولة فقط عن التعاملات التي تتم داخل مقر الشركة بمحافظة الجيزة وعنوانه: (السادس من أكتوبر - محور البوليفارد - أمام كومباوند جراند هايتس)، والتعاملات التي تتم داخل مقر الشركة بمحافظة مرسي مطروح وعنوانه: (واحة سيوة - السوق - مقابل فندق النخيل)، والتعاملات التي تتم من خلال الموقع الإلكتروني الخاص بالشركة وعنوانه:  (gomaacompany.com)، والتعاملات التي تتم من تطبيق الواتساب أو المكالمات الهاتفية علي الأرقام الآتية:

        </h1>

        <ul className='w-full text-start grid grid-cols-2 lg:grid-cols-3  gap-3 mt-4'>
          {
            contactData.length > 0 ? (
              <>
                {contactData[0].phone_numbers?.map((phoneNumber, index) => (
                  <li key={index}>
                    <a href={`tel:${phoneNumber.split("+")[0]}`} className='text-xl lg:text-2xl font-bold text-primary'>{phoneNumber}</a>
                  </li>
                ))}
              </>
            ) :
            (
              contacts?.map((contact) => {
                return (
                  <>
                    <td>
                      <a href={contact.link} className='text-xl lg:text-2xl font-bold text-primary'>{contact.title}</a>
                    </td>
                  </>
                )
              })
            )}
        </ul>
      </div>
    </section>
  )
}

export default MoreInfo