
import React from 'react'
import { useTranslation } from 'react-i18next'

function Section1({ data }) {
  const { t } = useTranslation()

  const handelScroll200px = () => {
    window.scrollTo({ top: 800, behavior: 'smooth' });
  }
  return (
    <>
      <div className='relative md:w-[90%] w-[95%] mx-auto md:mt-20 mt-10 '>
        <h1 className='md:text-5xl text-3xl font-bold  py-2'>{t("عن الشركة")}</h1>
        <div className='w-full'>
          <TextCard text={data.description ? data.description : "كما نقدم خدمات الستشارية هندسية متخصصة لتطوير وتنفيذ أنظمة طاقة هجينة مبتكرة تجمع بين الطاقةالشمسية ومصادر الطاقة التقليدية"} />
        </div>
      </div>
    </>
  )
}

const TextCard = ({ text }) => {
  const { t } = useTranslation();
  return (
    <div className="w-full md:text-lg   my-4">
      <p style={{ wordWrap: 'break-word' }} dangerouslySetInnerHTML={{ __html: text }}></p>
    </div>
  );
};

export default Section1