import { useEffect, useState } from "react";
import { FaChevronLeft, FaChevronRight } from "react-icons/fa"
import { Link } from "react-router-dom";
import GetMethod from "../../../functions/getMethod";
import { t } from "i18next";

function LatestPostsList() {
  const [posts, setPosts] = useState([])

  useEffect(() => {

    const getData = async () => {
      const res = await GetMethod("/posts");
      if (res?.status == 200 || res?.status == 201) {
        setPosts(res?.data?.data)
      }
    }

    getData()
  }, [])

  return (
    <div className="w-full md:max-w-md  bg-white   overflow-hidden border-2 border-primary p-4 rounded-lg">
      <h2 className="text-lg font-bold p-4 border-b text-center">{t("اخر المقالات")}</h2>
      <ul className="divide-y">
        {posts.slice(0, 6).map((post, index) => (
          <Link
            to={`/post/${post.id}`}
            key={index}
            className={`flex items-center gap-5 justify-between p-2 bg-[#F6F6F6] hover:bg-[#039E74] hover:text-white cursor-pointer transition-all rounded  my-5 w-full`}
          >
            <img className="h-[80px] w-[100px] object-cover rounded" src={post.image} alt="" />
            <div className="w-full">
              <span className="w-full text-right font-bold text-lg">{post.title.split(" ").slice(0, 2).join(" ") + (post.title.split(" ").length > 2 ? "..." : "")}</span>
              <span className=" float-left w-full ltr:scale-x-[-1]"><FaChevronLeft className="h-5 w-5  float-left" /></span>
            </div>
          </Link>
        ))}
      </ul>
    </div>
  )
}

export default LatestPostsList