import { useState, useEffect } from 'react';

const useStaticData = () => {
  const [staticData, setStaticData] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await fetch('/data/staticData.json');
        const data = await response.json();
        setStaticData(data);  // Cache the data in state
        setLoading(false);
      } catch (err) {
        setError('Error fetching static data');
        setLoading(false);
      }
    };

    if (!staticData) {
      fetchData();
    } else {
      setLoading(false);
    }
  }, [staticData]);

  return { staticData, loading, error };
};

export default useStaticData;
