
import React from 'react'
import { useTranslation } from 'react-i18next'
import { Link } from 'react-router-dom'
import Carousel from '../../../components/Carousel'

function Section1({ latestPosts }) {
  const { t } = useTranslation()
  const Arrows = {
    container: 'flex items-center rtl:flex-row-reverse justify-center  gap-3 absolute top-[-20%] left-[6%]  z-[10]',
    prev: (
      <button
        type='button'
        className='w-[50px] h-[50px] rounded-full p-3 bg-[#B5F2E2] drop-shadow bg-opacity-50 hover:bg-opacity-100 transition-all flex items-center justify-center cursor-pointer'
      >
        <img className='w-full h-full' src="/assets/icons/arrow-l.svg" alt="" />
      </button>
    ),
    next: (
      <div
        className=' w-[50px] h-[50px] rounded-full p-3 bg-[#B5F2E2] drop-shadow bg-opacity-50 hover:bg-opacity-100 transition-all flex items-center justify-center cursor-pointer'
      >
        <img className='w-full h-full' src="/assets/icons/arrow-r.svg" alt="" />
      </div>
    ),
  }

  return (
    <div className='lg:my-20 my-10 flex flex-col gap-10 w-[90%] mx-auto '>
      <h1 className='md:text-5xl text-3xl font-bold'>{t("أحدث المقالات")}</h1>
      <div className='flex flex-col md:flex-row items-center justify-between drop-shadow-md gap-5 '>
        <Carousel customStyles={" h-full  w-full"} dots={false} carouselClass="blogSection1" slidesPerView={4} customArrows={Arrows} breakpoints={{
          1024: {
            slidesPerView: 2,
            centeredSlides:false
          },
          768: {
            slidesPerView: 2,
            centeredSlides:false
          },
          640: {
            slidesPerView: 1,
            centeredSlides:false
          },
          0: {
            slidesPerView: 1,
            centeredSlides:false
          }
        }}>
          {
            latestPosts?.map((post, index) => (
              <Card key={index} id={post?.id} title={post?.title} text={post?.description} img={post?.image} link={`/post/${post?.id}`} />
            ))
          }
        </Carousel>

      </div>
    </div>
  )
}

export default Section1

export const Card = ({ id, title, text, img }) => {
  const { t } = useTranslation()

  return (
    <div data-aos="fade-up" className='relative  md:w-[730px]  m-2 lg:h-[411px] h-[280px] bg-secondary rounded-lg  overflow-hidden group'>
      <span className='absolute bottom-0 left-0 w-full  bg-gradient-to-t  from-[#1E293B]   to-transparent h-full transition-all'></span>
      {/* <div className='absolute bottom-0 left-0  w-full  transition-all h-full bg-black bg-opacity-50 rounded-lg'></div> */}
      <img src={img} className='w-full h-full object-cover rounded-lg' alt="" />
      <div className=' absolute mx-auto w-[97%] min-h-[30%] rounded-md rounded-r-none   items-center justify-between bottom-10  text-white p-4 align-bottom  '>
        <Link to={`/post/${id}`} className='lg:w-1/2'>
          <h1 className='lg:text-3xl text-lg font-bold  break-words group-hover:text-primary'>{(title?.split(80).includes(' ') ? title?.split(' ').slice(0, 3).join(' ') : title?.slice(0, 15))}</h1>
          {/* <p className='lg:text-2xl' style={{ overflowWrap: 'break-word' }}>{(text?.split(80).includes(' ') ? text?.split(' ').slice(0, 3).join(' ') : text?.slice(0, 20))}...</p> */}
        </Link>
        <Link to={`/post/${id}`} className='flex items-center justify-center cursor-pointer gap-2 mt-2  bg-primary rounded-3xl w-[150px]  xl:p-2 p-1 drop-shadow-lg float-left group'>
          <h1 className='text-white font-bold'>{t("معرفة المزيد")}</h1>
          <div className='group-hover:translate-x-[-6px] transition-all'>
            <svg width="19" height="19" viewBox="0 0 19 19" fill="#ffffff" xmlns="http://www.w3.org/2000/svg">
              <path d="M10.8857 16.3795L10.1028 17.1576C9.77137 17.487 9.237 17.4854 8.91106 17.1539L2.09758 10.3022C1.76812 9.97073 1.76975 9.43636 2.10122 9.11042L8.95644 2.29695C9.28791 1.96749 9.82229 1.96912 10.1482 2.30059L10.9263 3.08344C11.2593 3.41844 11.2506 3.96334 10.9086 4.28925L6.65993 8.31223L16.7638 8.34309C17.2314 8.34452 17.6064 8.72184 17.605 9.18941L17.6015 10.3144C17.6001 10.782 17.2228 11.157 16.7552 11.1556L6.65134 11.1247L10.8753 15.1736C11.2188 15.5016 11.2242 16.0465 10.8857 16.3795Z" fill="white" />
            </svg>
          </div>
        </Link>
      </div>
    </div>
  )
}