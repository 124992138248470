import React from 'react'
import useStaticData from '../hooks/useStaticData'
import { Helmet } from 'react-helmet'
import { t } from 'i18next'

export default function PageTitle({ title , subtitle  }) {
  const { staticData } = useStaticData()
  return (
    <Helmet>
      <title>{t(staticData?.meta?.title)} - {t(title)} {subtitle ? ` - ${t(subtitle)}` : ''} </title>
    </Helmet>
  )
}
