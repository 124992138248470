import React, { useEffect, useState } from 'react'
import { Link, useParams } from 'react-router-dom';
import Title from '../../components/Title';
import VideoSection from './../Home/components/VideoSection';
import { useTranslation } from 'react-i18next';
import GetMethod from '../../functions/getMethod';
import VideoComponent from '../../components/VideoComponent';
import Carousel from './../../components/Carousel';
import PageHeader from '../../components/PageHeader';
import { FaStar } from 'react-icons/fa';
import PageTitle from '../../components/PageTitle';

export default function ProjectDetailsPage() {
  const { id } = useParams();
  const { t } = useTranslation()
  const [project, setProject] = useState({})
  const [projectImages, setProjectImages] = useState([])
  const [loading, setLoading] = useState(true)
  useEffect(() => {
    const fetchData = async () => {
      const response = await GetMethod(`/projects/${id}`)

      if (response?.status === 200 || response?.status === 201) {
        setProject(response.data?.data)
        console.log(response.data?.data)

        const imagesRes = await GetMethod(`/ProjectImage`)

        if (imagesRes?.status === 200 || imagesRes?.status === 201) {
          setProjectImages(imagesRes.data?.data.filter(image => image.project_id == id))

          setLoading(false)
        }
      }
    }
    fetchData()


  }, [])

  const Arrows = {
    container: 'flex items-center rtl:flex-row-reverse justify-center  gap-3 absolute bottom-[-15%] transform -translate-x-1/2 left-1/2  z-[10]',
    prev: (
      <button
        type='button'
        className='w-[50px] h-[50px] rounded-full p-3 bg-[#B5F2E2] drop-shadow bg-opacity-50 hover:bg-opacity-100 transition-all flex items-center justify-center cursor-pointer'
      >
        <img className='w-full h-full' src="/assets/icons/arrow-l.svg" alt="" />
      </button>
    ),
    next: (
      <div
        className=' w-[50px] h-[50px] rounded-full p-3 bg-[#B5F2E2] drop-shadow bg-opacity-50 hover:bg-opacity-100 transition-all flex items-center justify-center cursor-pointer'
      >
        <img className='w-full h-full' src="/assets/icons/arrow-r.svg" alt="" />
      </div>
    ),
  }


  return (
    <>
    <PageTitle  title={"المشاريع"}  subtitle={project?.name}/>
      <PageHeader title={project?.name} paths={[
        {
          name: "الرئيسية",
          path: "/"
        },
        {
          name: "المشاريع",
          path: "/projects"
        }, {
          name: project?.name,
          path: ""
        }]} />



      <div className='w-full h-28 relative hidden'>
        <span className='absolute transform -translate-x-1/2 translate-y-[-90%] top-1/2 left-1/2 '><Card /></span>
      </div>


      <div className='md:w-[90%] w-[95%] mx-auto'>
        <img src={project?.image_url} className=' w-full md:h-[500px] h-[300px] object-cover ' alt="" />
        <h1 className='text-3xl font-bold my-10 '>{project?.name}</h1>
        <p className='w-full lg:text-xl z-[5]   h-full' style={{ overflowWrap: 'break-word' }} dangerouslySetInnerHTML={{ __html: project?.description?.replace(/\n/g, "<br>") }}></p>

      </div>

      <div className='w-full mx-auto mt-20 mb-20 flex flex-col items-center justify-center  '>
        <h1 className='xltext-3xl text-2xl text-center font-bold'>{t("شاهد تفاصيل المشروع")}</h1>


        {
          projectImages?.length > 0 ? (

            <Carousel customStyles={"gap-5 my-10 md:w-[90%] w-[95%] "} arrows={true} customArrows={Arrows} carouselClass={'hero'} dots={false} autoPlay={true} loop={true} loading={loading} >
              {
                project?.video_link && project?.video_link.includes("https") ?
                  (<div className='w-full h-full mx-auto object-cover '>
                    <VideoComponent video_url={project?.video_link} />
                  </div>)
                  :
                  (<>
                  </>)
              }
              {
                projectImages?.map((item, index) => (
                  <div key={index} className='carsousel-cell  w-full h-[440px]  text-white flex items-center  justify-center gap-2 p-5 '>
                    <img className='h-[440px] w-full object-cover  z-[-1] absolute top-0 left-0 rounded-lg' src={item.image_path} alt="" />
                  </div>
                ))
              }
            </Carousel>
          )
            : (
              project?.video_link && project?.video_link.includes("https") ?
                (<div className='w-[50%] h-[500px] mx-auto object-cover my-10'>
                  <VideoComponent video_url={project?.video_link} />
                </div>)
                :
                (<>
                </>)
            )
        }



      </div>
    </>
  )
}


function Card() {
  return (
    <div className="bg-white shadow-md rounded-lg p-6 w-full max-w-3xl">
      <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-4 gap-4 text-right">
        <div className="flex flex-col items-end">
          <h2 className="text-lg font-semibold mb-2">نوع المشروع</h2>
          <p className="text-gray-600">محطة طاقة شمسية</p>
        </div>
        <div className="flex flex-col items-end">
          <h2 className="text-lg font-semibold mb-2">تاريخ التنفيذ</h2>
          <p className="text-gray-600">01/10/2024</p>
        </div>
        <div className="flex flex-col items-end">
          <h2 className="text-lg font-semibold mb-2">المكان</h2>
          <p className="text-gray-600">مدينة نصر - الحى السابع</p>
        </div>
        <div className="flex flex-col items-end">
          <h2 className="text-lg font-semibold mb-2">التقييم</h2>
          <div className="flex flex-row-reverse">
            {[...Array(5)].map((_, index) => (
              <FaStar key={index} className="w-5 h-5 fill-yellow-400 text-yellow-400" />
            ))}
          </div>
        </div>
      </div>
    </div>
  )
}