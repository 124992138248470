import React, { useEffect, useState } from 'react'
import Carousel from '../../../components/Carousel'
import GetMethod from '../../../functions/getMethod'
import { useTranslation } from 'react-i18next'
import { FaArrowAltCircleDown } from 'react-icons/fa'

export default function Hero() {
  const [sliders, setSliders] = React.useState([])
  const [counters, setCounters] = React.useState({})
  const [loading, setLoading] = React.useState(true)
  const { t } = useTranslation()
  const LANG = localStorage.getItem('LANGUAGE') === "en"




  useEffect(() => {
    const fetchData = async () => {
      const res = await GetMethod("/home_sliders")
      const response2 = await GetMethod("/counters")
      if (res?.status == 200 || res?.status == 201) {
        setSliders(res?.data?.data)
        setLoading(false)
      }
      if (response2?.status === 200) {
        setCounters(response2.data?.data)
      }
    }
    fetchData()
  }, [])



  const Arrows = {
    container: 'flex items-center rtl:flex-row-reverse justify-center gap-3 absolute  left-1/2 transform -translate-x-1/2 z-[10] bottom-[12%] md:bottom-[20%] sm:rtl:left-[10%] sm:ltr:right-[10%]',

    prev: (
      <button
        type='button'
        className='md:w-[60px] w-[40px] md:h-[60px] h-[40px] rounded-full p-3 bg-[#B5F2E2] drop-shadow hover:bg-primary bg-opacity-100 transition-all flex items-center justify-center cursor-pointer group'
      >
        <svg className='group-hover:fill-[#B5F2E2]  fill-primary' width="22" height="22" viewBox="0 0 22 22" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path d="M12.5707 19.8641L11.5301 20.9047C11.0895 21.3453 10.377 21.3453 9.94102 20.9047L0.828516 11.7969C0.387891 11.3562 0.387891 10.6437 0.828516 10.2078L9.94102 1.0953C10.3816 0.654675 11.0941 0.654675 11.5301 1.0953L12.5707 2.13593C13.016 2.58124 13.0066 3.3078 12.552 3.74374L6.90352 9.12499L20.3754 9.12499C20.9988 9.12499 21.5004 9.62655 21.5004 10.25V11.75C21.5004 12.3734 20.9988 12.875 20.3754 12.875L6.90352 12.875L12.552 18.2562C13.0113 18.6922 13.0207 19.4187 12.5707 19.8641Z" />
        </svg>

      </button>
    ),
    next: (
      <div
        className=' md:w-[60px] w-[40px] md:h-[60px] h-[40px] rounded-full p-3 bg-[#B5F2E2] drop-shadow hover:bg-primary transition-all flex items-center justify-center cursor-pointer group'
      >
        <svg className='group-hover:fill-[#B5F2E2]  fill-primary' width="22" height="22" viewBox="0 0 22 22" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path d="M9.4293 2.13595L10.4699 1.09533C10.9105 0.654699 11.623 0.654699 12.059 1.09533L21.1715 10.2031C21.6121 10.6438 21.6121 11.3563 21.1715 11.7922L12.059 20.9047C11.6184 21.3453 10.9059 21.3453 10.4699 20.9047L9.4293 19.8641C8.98398 19.4188 8.99336 18.6922 9.44805 18.2563L15.0965 12.875L1.62461 12.875C1.00117 12.875 0.499609 12.3734 0.499609 11.75L0.499609 10.25C0.499609 9.62657 1.00117 9.12501 1.62461 9.12501L15.0965 9.12501L9.44805 3.74376C8.98867 3.30783 8.9793 2.58126 9.4293 2.13595Z"  />
        </svg>

      </div>
    ),
  }

  return (
    <>

      <section className='relative md:h-[650px]  h-[450px] '>



        {sliders.length > 0 &&

          <Carousel customStyles={"gap-5  w-full"} carouselClass={'hero'} dots={false} autoPlay={true} loop={true} loading={loading}
            customArrows={Arrows}
          >
            {
              sliders?.map((item, index) => (
                <div key={index} className='carsousel-cell  w-full md:h-[750px]  h-[450px] text-white flex items-center  justify-center gap-2'>
                  <div className=' bg-black bg-opacity-60 h-full  w-[100%] flex flex-col gap-2'>
                    {/* layer */}
                    <span className='  bg-gradient-to-l from-[#09624A] to-transparent absolute top-0 left-0 w-full h-[840px] '></span>
                    {
                      item.video ?
                        <video className='h-[840px] w-full object-cover  z-[-1] absolute top-0 left-0' src={item.video} autoPlay loop={true} muted />
                        :
                        <img className='h-[840px] w-full object-cover  z-[-1] absolute top-0 left-0' src={item.image} alt="" />
                    }
                    <div className='z-[1] md:w-[90%]  mx-auto h-full  flex flex-col items-start justify-center '>
                      <h1 className=' text-white text-center  md:text-right p-4  md:text-2xl  text-sm mx-auto md:m-0'>{item.main_title}</h1>
                      <p className='lg:text-5xl  xl:w-[50%]  sm:w-[60%]	 font-extrabold text-wrap text-center md:text-right mx-auto md:m-0'>{item.sub_title}</p>

                      <div className='flex flex-col md:flex-row items-center justify-center gap-8 mt-5 w-full md:w-max'>

                        <div onClick={() => window.scrollTo(0, 1000)} className=' rounded-[60px] p-2  mx-auto  md:w-[193px] h-[40px] md:h-[50px] flex items-center justify-center gap-4 border border-white cursor-pointer'>
                          <h1>{t('تعرف على المزيد')}</h1>
                          <svg className=' ltr:scale-x-[-1]' width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M10.1775 15.6481L9.39707 16.4285C9.0666 16.759 8.53223 16.759 8.20527 16.4285L1.3709 9.59766C1.04043 9.26719 1.04043 8.73282 1.3709 8.40587L8.20527 1.57149C8.53574 1.24102 9.07012 1.24102 9.39707 1.57149L10.1775 2.35196C10.5115 2.68594 10.5045 3.23087 10.1635 3.55782L5.92715 7.59376L16.0311 7.59376C16.4986 7.59376 16.8748 7.96993 16.8748 8.43751V9.56251C16.8748 10.0301 16.4986 10.4063 16.0311 10.4063H5.92715L10.1635 14.4422C10.508 14.7691 10.515 15.3141 10.1775 15.6481Z" fill="#B5F2E2" />
                          </svg>
                        </div>


                        <a href='#video' className='hover:transform hover:scale-105 transition-all  items-center justify-center gap-2  hidden md:flex'>
                          <p>{t('شاهد الفيديو')}</p>
                          <svg width={40} height={40} viewBox="0 0 40 40" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <rect width={40} height={40} rx={20} fill="#B5F2E2" fillOpacity="0.5" />
                            <rect x={3} y={3} width={34} height={34} rx={17} fill="white" />
                            <path d="M27.262 18.7081L16.2624 12.2052C15.3687 11.6771 14 12.1896 14 13.4958V26.4984C14 27.6702 15.2718 28.3765 16.2624 27.789L27.262 21.2892C28.2432 20.7111 28.2463 19.2862 27.262 18.7081Z" fill="#039E74" />
                          </svg>


                        </a>



                      </div>
                    </div>
                  </div>
                </div>
              ))
            }
          </Carousel>

        }


      </section>


      <section className='  bg-white drop-shadow-lg  md:w-[90%] w-[95%] mx-auto md:h-[240px] min-h-[100px] flex items-center justify-end relative z-[1] rounded '>

        <div dir='ltr' className='z-[1]  flex flex-col sm:flex-row justify-evenly md:items-center items-end px-5  py-5   w-full h-full lg:gap-8 gap-4 '>
          {
            counters[0] ?
              <>
                <Card title={counters[0]?.title} number={counters[0]?.number} image="/assets/home/hero/v4.svg" isLine={false} />
                <Card title={counters[1]?.title} number={counters[1]?.number} image="/assets/home/hero/v3.svg" />
                <Card title={counters[2]?.title} number={counters[2]?.number} image="/assets/home/hero/v2.svg" />
                <Card title={counters[3]?.title} number={counters[3]?.number} image="/assets/home/hero/v1.svg" />
              </>
              :
              <>
                <Card title="المشاريع" number={30} image="/assets/home/hero/v4.svg" isLine={false} />
                <Card title="الجوائز" number={155} image="/assets/home/hero/v3.svg" />
                <Card title="أعمال العملاء" number={20} image="/assets/home/hero/v2.svg" />
                <Card title="العملاء" number={10} image="/assets/home/hero/v1.svg" />
              </>
          }

        </div>
      </section>



    </>

  )
}



function Card({ title, number, image, isLine = true }) {
  const { t } = useTranslation();
  return (
    <>

      {isLine && <span className='h-[80%] bg-primary w-1'></span>}
      <div className={`flex flex-col md:items-center items-end justify-center  md:gap-2  `}>
        <p className='md:text-[90px] mb-2 text-[40px] font-bold text-primary'>+{number}</p>
        <div className='flex items-center  gap-2'>

          <h1 className='text-xl  '> {t(title)}</h1>
          <span className='bg-primary bg-opacity-50 rounded w-[20px] h-[20px] '></span>
        </div>
      </div>

    </>
  )

}