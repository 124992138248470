import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { FaYoutube, FaFacebookF, FaWhatsapp, FaInstagram, FaTwitter, FaLinkedin } from 'react-icons/fa'; // Import React Icons
import GetMethod from '../functions/getMethod';

export default function SocialIcons({ containerClass,iconClass}) {
  const [socialLinks, setSocialLinks] = useState(null);
  const LANG = localStorage.getItem('LANGUAGE') || 'ar';

  // Static social media links
  const staticSocialIcons = [
    { name: 'youtube', link: 'https://www.youtube.com/@GOMAACOMPANY' },
    { name: 'facebook', link: 'https://www.facebook.com/profile.php?id=100063806260379&mibextid=JRoKGi' },
    { name: 'whatsapp', link: 'https://wa.me/+201008309836' }
  ];

  const socialIcons = {
    youtube: <FaYoutube />,
    facebook: <FaFacebookF />,
    whatsapp: <FaWhatsapp />,
    instagram: <FaInstagram />,
    twitter: <FaTwitter />,
    linkedin: <FaLinkedin />
  };

  useEffect(() => {
    const fetchData = async () => {
      const response = await GetMethod('/social-links');
      if (response?.status === 200 || response?.status === 201) {
        setSocialLinks(response?.data?.social_links[0]);
      }
    };

    fetchData();
  }, []);

  return (
    <div dir='ltr' className={`flex justify-center items-center gap-1  text-[#B5F2E2] ${containerClass} h-full`}>
      {socialLinks ? (
        <>
          {socialLinks.facebook && <SocialIcon iconClass={iconClass} name="facebook" icons={socialIcons} link={socialLinks.facebook} />}
          {socialLinks.whatsapp && <SocialIcon iconClass={iconClass} name="whatsapp" icons={socialIcons} link={socialLinks.whatsapp} />}
          {socialLinks.youtube && <SocialIcon iconClass={iconClass} name="youtube" icons={socialIcons} link={socialLinks.youtube} />}
          {socialLinks.instagram && <SocialIcon iconClass={iconClass} name="instagram" icons={socialIcons} link={socialLinks.instagram} />}
          {socialLinks.twitter && <SocialIcon iconClass={iconClass} name="twitter" icons={socialIcons} link={socialLinks.twitter} />}
          {socialLinks.linkedin && <SocialIcon iconClass={iconClass} name="linkedin" icons={socialIcons} link={socialLinks.linkedin} />}
        </>
      ) : (
        staticSocialIcons.map((icon, index) => (
          <SocialIcon iconClass={iconClass} key={index} name={icon.name} icons={socialIcons} link={icon.link} />
        ))
      )}
    </div>
  );
}

const SocialIcon = ({ name, link, icons,iconClass="text-xl" }) => {
  const icon = icons[name];
  return (
    <Link className={` h-full flex items-center justify-center   transition-all duration-300 w-[40px] ${iconClass}`} to={link} target="_blank">
      {icon}
    </Link>
  );
};
