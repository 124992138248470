import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import postMethod from './../functions/postMethod';
import Swal from 'sweetalert2';
import { t } from 'i18next';
import SocialIcons from './SocialMedia';
import useStaticData from '../hooks/useStaticData';

export default function Footer() {
  const [email, setEmail] = useState("");
  const { t } = useTranslation();
  const { staticData } = useStaticData()

  const sendSubscription = async (e) => {
    e.preventDefault()

    if (!email) {
      return Swal.fire({
        icon: 'error',
        title: 'يرجي ادخال بريدك الالكتروني',
      })
    }

    if (!email.includes("@") || !email.includes(".")) {
      return Swal.fire({
        icon: 'error',
        title: 'البريد الالكتروني غير صحيح',
      })
    }

    Swal.fire({
      title: 'جاري التحميل...',
      html: 'يرجى الانتظار بينما يتم معالجة الطلب .',
      allowOutsideClick: false,
      showCloseButton: false,
      showConfirmButton: false,
      onBeforeOpen: () => {
        Swal.showLoading();
      }
    })
    const res = await postMethod("/subscriptions", { email })
    if (res?.status == 200 || res?.status == 201) {
      setEmail("")
      Swal.fire({
        icon: 'success',
        title: 'تم الاشتراك بنجاح',
        showConfirmButton: false,
        timer: 1500
      })
    }
  }
  return (
    <footer id='footer' className='bg-secondary w-full pt-10 text-white  '>

      <div className='flex flex-col flex-wrap md:flex-row items-center justify-center xl:mx-auto gap-5 mt-5'>
        <Card link={`tel:${staticData?.footer?.number.split('+')[0]}`} title={t("اتصل بنا")} desc={staticData?.footer?.number} icon={"/assets/footer/phone.svg"} />
        <Card link={`mailto:${staticData?.footer?.email}`} title={t("البريد الإلكتروني")} desc={staticData?.footer?.email} icon={"/assets/footer/mail.svg"} />
        <Card link={staticData?.footer?.location_url} title={t("موقع الشركة")} desc={staticData?.footer?.location} icon={"/assets/footer/location.svg"} />
      </div>

      <div className=' my-[30px]  w-[90%] mx-auto'>
        <div className=' grid grid-cols-1 md:grid-cols-4 lg:grid-cols-5 mx-auto  lg:gap-0 gap-7'>
          <div className='md:col-span-2 flex flex-col items-start justify-center gap-3  '>
            <img className='w-[19rem]' src="/assets/logo-dark.svg" alt="" />
            <p className='  text-gray-400'>
              {t("شركة جمعة هي شركة مساهمة مصرية تأسست عام 2010 وتخصصت في تصميم وتركيب وصيانة أنظمة الطاقة المتجددة كأنظمة الطاقة الشمسية")}
            </p>
            <SocialIcons containerClass="flex gap-4 " iconClass={"w-auto text-3xl text-primary hover:text-white hover:scale-150"} />
          </div>

          <div className='flex flex-col items-start justify-start '>
            <h1 className='font-bold md:text-3xl text-2xl mb-4'>{t("الخدمات")}</h1>
            <div className='flex flex-col gap-2 '>
              <h1 className='   text-gray-400 hover:text-primary'>{t("الطاقة الشمسية")}</h1>
              <h1 className='   text-gray-400 hover:text-primary'>{t("التوريد والتركيب")}</h1>
              <h1 className='   text-gray-400 hover:text-primary'>{t("الصيانة")}</h1>
              <h1 className='   text-gray-400 hover:text-primary'>{t("تواصل")}</h1>
            </div>
          </div>

          <div className='flex flex-col items-start justify-start  w-full md:w-auto'>
            <h1 className='font-bold md:text-3xl text-2xl mb-4'>{t("الشركة")}</h1>
            <div className='flex flex-col gap-2 '>
              <Link to={"/"} className='  text-gray-400 hover:text-primary'>{t("الرئيسية")}</Link>
              <Link to={"/about-us"} className='  text-gray-400 hover:text-primary'>{t("من نحن")}</Link>
              <Link to={"/services"} className='  text-gray-400 hover:text-primary'>{t("الخدمات")}</Link>
              <Link to={"/contact-us"} className='  text-gray-400 hover:text-primary'>{t("تواصل معنا")}</Link>
              <Link to={"/info"} className='  text-gray-400 hover:text-primary'>{t("تنويه")}</Link>
            </div>

            <div className='flex flex-col items-start justify-start  mt-[24px] '>
            <h1 className='font-bold md:text-3xl text-2xl mb-4'>{t("روابط سريعة")}</h1>
            <div className='flex flex-col gap-2 '>
              <Link to={"/"} className='  text-gray-400 hover:text-primary'>{t("الشروط والأحكام")}</Link>
              <Link to={"/about-us"} className='  text-gray-400 hover:text-primary'>{t("سياسة الخصوصية")}</Link>
            </div>

            
          </div>
          </div>

          <div className='bg-primary rounded-lg xl:p-4 p-1 w-full md:w-auto md:col-span-4 lg:col-span-1 '>

            <div className='flex items-center justify-between  mx-auto w-[90%]'>

              <div>
                <h1 className='md:text-4xl text-2xl font-extrabold'>{t("اشترك")}</h1>
                <p className='md:text-lg '>{t("ليصلك كل جديد")}</p>
              </div>
              
              <img className='xl:w-24 xl:h-24 w-16 h-16' src="/assets/footer/bell.svg" alt="" />
            </div>
            <form onSubmit={sendSubscription} className='flex flex-col items-center justify-center mt-5 w-[90%] mx-auto'>
              <input className='rounded  focus:outline outline-primary  p-4   w-full  text-black bg-[#0B7559] bg-opacity-50 border-2 border-[#0B7559] placeholder:text-white'  onChange={(e) => setEmail(e.target.value)} type="text" name="" id="" placeholder={t('اكتب البريد الإلكتروني')} />
              <button type='submit' className='md:text-lg px-6 p-2 bg-white text-secondary hover:bg-opacity-80 duration-200 mt-4 w-[124px] font-extrabold rounded-[50px] flex items-center justify-center cursor-pointer'>{t("اشتراك")}</button>
            </form>
          </div>


        </div>




      </div>
      <div className='flex flex-col md:flex-row items-center xl:justify-between justify-center xl:px-20 gap-4 bg-primary w-full min-h-[40px] py-5 md:py-0'>

        <p className='text-[13px] md:text-base'>{t("شركة جمعة لأنظمة الطاقة الشمسية | جميع الحقوق محفوظة")} {new Date().getFullYear()} </p>

        <Link className='' to={"https://guessitt.com"}>{t("بواسطة")} {"{ Guessit }"}</Link>
      </div>
    </footer>
  );
}


const Card = ({ title, desc, icon,link }) => {
  return (
    <div className="  md:min-w-[418px] min-w-[90%] xl:h-[198px] min-h-[120px] bg-gray-800 rounded-lg shadow-lg flex flex-col items-center justify-center p-4 group">
      <div className="bg-emerald-500 rounded-full p-3 mb-2">
        <img src={icon} className="w-6 h-6 text-white" />
      </div>
      <p className="text-white text-2xl mb-1 text-nowrap">{t(title)}</p>
      <Link target='_blank' to={link}  className="text-gray-400 xl:text-xl text-nowrap group-hover:text-primary">{t(desc)}</Link>
    </div>
  );
};

