import React, { useState , useEffect } from 'react'
import Section1 from './components/Section1';
import Section2 from './components/Section2';
import Section3 from './components/Section3';
import Section4 from './components/Section4';
import GetMethod from './../../functions/getMethod';
import PageHeader from '../../components/PageHeader';
import VideoSection from '../Home/components/VideoSection';
import ConatctUsSection from '../Home/components/ConatctUsSection';
import PageTitle from '../../components/PageTitle';
export default function AboutUsPage() {
  const [data, setData] = useState([])
  useEffect(() => {
    const fetchData = async () => {
      const response = await GetMethod("/about-us")

      if (response?.status === 200) {
        setData(response.data?.data)
      } 
    }
    fetchData()
  }, [])

  
  return (
    <>
    <PageTitle  title={"من نحن"} />
    <PageHeader title={"من نحن"} paths={[
      {
        name: "الرئيسية",
        path: "/"
      },
      {
      name: "من نحن",
      path: "/about-us"
    }]} />
      <Section1 data={{...data[0]}}/>
      <Section2 data={{...data[0]}}/>
      <VideoSection />
      <Section3 data={{...data[0]}}/>
      <ConatctUsSection />
    </>
  )
}
