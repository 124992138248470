import React, { useEffect, useState } from 'react';
import GetMethod from './../../../functions/getMethod';
import { useTranslation } from 'react-i18next';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import CategoriesList from './CategoriesList';
import MainPagination from './../../../components/Pagination';

function Section2({ postsList = [] }) {
  const [posts, setPosts] = useState([...postsList]);
  const [currentPage, setCurrentPage] = useState(1);
  const [postsPerPage] = useState(6); // Number of posts per page
  const [categoriesPerPage] = useState(4); // Number of categories per page
  const [currentCategory, setCurrentCategory] = useState(null);
  const { t } = useTranslation();
  const LANG = localStorage.getItem('LANGUAGE') || 'ar';

  const location = useLocation();
  useEffect(() => {
    const queryParams = new URLSearchParams(location.search);
    const category = queryParams.get('category');
    if (category) {
      setCurrentCategory(category);
    }
  }, [location]);


  useEffect(() => {
    setPosts([...postsList]);
  }, [postsList])
  


  // Get current posts based on pagination and category filter
  const filteredPosts = currentCategory
    ? posts.filter(post => post.category?.name === currentCategory)
    : posts;

  // Pagination for posts
  const indexOfLastPost = currentPage * postsPerPage;
  const indexOfFirstPost = indexOfLastPost - postsPerPage;
  const currentPosts = filteredPosts.slice(indexOfFirstPost, indexOfLastPost);

  const paginate = (pageNumber) => {
    setCurrentPage(pageNumber)
  };


  const navigate = useNavigate();

  const handleRemoveCategoryFilter = () => {
    setCurrentCategory(null);
  }
  return (
    <section className='flex flex-col lg:flex-row items-start w-[90%] mx-auto gap-5 relative'>
      <div className='w-full lg:my-20 my-10 flex flex-col'>
        {currentPosts.length > 0 ?
          <section className={` grid xl:grid-cols-3 sm:grid-cols-2 grid-cols-1 lg:gap-1 xl:gap-3`}>
            {currentPosts.map((post) => (
              <PostCard key={post.id} id={post.id} image={post?.image} description={post?.description} title={post?.title} link={`/post/${post.id}`} />
            ))}
          </section>
          :
          (
            <>
              <h1 className='text-3xl text-center  font-bold my-10'>{t("لا يوجد مقالات")}</h1>
            </>
          )
        }

        <MainPagination
          totalPages={Math.ceil(filteredPosts.length / postsPerPage)}
          onPageChange={paginate}
        />
        

      </div>
    </section>
  );
}



export function PostCard({ description, image, link, title }) {
  const { t } = useTranslation()
  return (
    <div data-aos="fade-up" className=' relative   lg:h-[476px]  h-[400px] flex flex-col items-end justify-between pb-3  gap-5 bg-white border rounded group p-6 group'>
      <Link to={link} className='w-full  h-[50%] relative overflow-hidden rounded '>
        <img className='h-full w-full object-cover drop-shadow rounded hover:scale-[1.1] transition-all' src={image} alt="" />
      </Link>

      <div>
        <Link to={link} className='lg:text-3xl text-xl font-bold  break-words group-hover:text-primary transition-all'>{(title?.split(80).includes(' ') ? title?.split(' ').slice(0, 3).join(' ') : title?.slice(0, 15))}</Link>
        <p className=' md:text-lg  px-1 text-gray-500 mt-3' dangerouslySetInnerHTML={{ __html: description?.slice(0, 20).includes(' ') ? description?.split(' ').slice(0, 12).join(' ') + '...' : description?.slice(0, 12) }}></p>
      </div>

      <Link to={link} className='flex items-center  float-left justify-end cursor-pointer gap-2    rounded-3xl w-[150px] p-2  group-hover:-translate-x-4 transition-all '>
        <h1 className='text-primary font-bold'>{t("معرفة المزيد")}</h1>
        <svg width="19" height="19" viewBox="0 0 19 19" fill="#ffffff" xmlns="http://www.w3.org/2000/svg">
          <path d="M10.8857 16.3795L10.1028 17.1576C9.77137 17.487 9.237 17.4854 8.91106 17.1539L2.09758 10.3022C1.76812 9.97073 1.76975 9.43636 2.10122 9.11042L8.95644 2.29695C9.28791 1.96749 9.82229 1.96912 10.1482 2.30059L10.9263 3.08344C11.2593 3.41844 11.2506 3.96334 10.9086 4.28925L6.65993 8.31223L16.7638 8.34309C17.2314 8.34452 17.6064 8.72184 17.605 9.18941L17.6015 10.3144C17.6001 10.782 17.2228 11.157 16.7552 11.1556L6.65134 11.1247L10.8753 15.1736C11.2188 15.5016 11.2242 16.0465 10.8857 16.3795Z" fill="#039E74" />
        </svg>
      </Link>



    </div>
  )

}



export default Section2;
