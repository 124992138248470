import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import GetMethod from '../../../functions/getMethod';
import { Link } from 'react-router-dom';
import { FaPhone, FaPhoneAlt } from 'react-icons/fa';
import { IoMail } from 'react-icons/io5';
function Section2() {
  const { t } = useTranslation()

  const [contactData, setContactData] = useState([]);

  useEffect(() => {
    const fetchData = async () => {
      const response = await GetMethod("/contact-data")
      if (response?.status === 200) {
        setContactData(response.data?.data)
      }
    }
    fetchData()
  }, [])
  return (
    <>
      <div className=' md:w-[90%] w-[95%] flex flex-col md:flex-row justify-between  mx-auto my-10   gap-8'>

        <section className="md:w-[50%] w-full min-h-[500px]   bg-emerald-500 rounded-lg shadow-md md:p-8 p-3 gap-5">
          <h1 className='text-3xl font-bold text-white'>{t("معلومات الاتصال")}</h1>

          <div className='bg-white bg-opacity-10 backdrop-blur mt-8 p-2 rounded '>

            <div className='flex items-start justify-between w-full  p-5'>
              <h1 className='font-extrabold mb-4  text-white text-xl '>
                {t("ارقام الهاتف")}
              </h1>
              <span className='p-4 rounded-full bg-white bg-opacity-10 backdrop-blur '><FaPhoneAlt className='text-xl text-white' /></span>
            </div>

            <div className='text-center flex flex-col gap-3  items-start justify-start   '>
              {contactData.length > 0 && (
                <>
                  {contactData[0].phone_numbers?.map((phoneNumber, index) => (
                    <h1   className='hover:text-gray-200 transition-all text-white flex gap-2 items-center justify-start'>
                      <span  className='bg-primary w-5 h-3 rounded-full p-[2px] px-5'></span>
                      <Link    to={`tel:${phoneNumber.split("+")[0]}`} className=' text-lg'>{phoneNumber}</Link>
                    </h1>
                  ))}
                </>
              )}
            </div>

          </div>

          <div className='bg-white bg-opacity-10 backdrop-blur my-6 p-2 rounded'>

            <div className='flex items-start justify-between w-full  p-5'>
              <h1 className='font-bold mb-4  text-white text-xl'>
                {t("البريد الإلكتروني")}
              </h1>
              <span className='p-4 rounded-full bg-white bg-opacity-10 backdrop-blur '> <IoMail className='text-xl text-white' /></span>
            </div>

            <div className='flex flex-col gap-1'>
              <div className='flex items-center gap-2 text-lg text-white'>
                <span className='bg-primary w-5 h-3 rounded-full p-[2px] px-5'></span>
                <Link className='hover:text-gray-200' to={`mailto:info@gomaacompany.com`}>info@gomaacompany.com</Link>

              </div>
              <div className='flex items-center gap-2 text-lg text-white'>
                <span className='bg-primary w-5 h-3 rounded-full p-[2px] px-5'></span>
                <Link className='hover:text-gray-200' to={`mailto:business@gomaacompany.com`}>business@gomaacompany.com</Link>
              </div>
              <div className='flex items-center gap-2 text-lg text-white'>
                <span className='bg-primary w-5 h-3 rounded-full p-[2px] px-5'></span>
                <Link className='hover:text-gray-200' to={`mailto:job@gomaacompany.com`}>job@gomaacompany.com</Link>
              </div>
            </div>

          </div>

        </section>

        <section className='md:w-[70%] items-center justify-start flex flex-col gap-8 '>
            {
              contactData[0]?.addresses && (
                <>
                  <div   className='border border-primary rounded-lg p-6 w-full flex flex-col gap-6 group'>
                    <Text text={contactData[0].addresses[0]} link={"https://maps.app.goo.gl/8z4YrjoBNm9f6jQN8"} />
                    <div className='w-full h-[300px]'>
                      <iframe className='w-full h-full rounded-[10px]  ' src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3426.254134751761!2d25.519985215115636!3d29.200873182147344!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x147aaf441b073a1f%3A0x306390f7c11ecb1e!2s%D8%B4%D8%B1%D9%83%D8%A9%20%D8%AC%D9%85%D8%B9%D8%A9%20%D9%84%D9%84%D8%AA%D8%B5%D8%AF%D9%8A%D8%B1%20%D9%88%D8%A3%D9%86%D8%B8%D9%85%D8%A9%20%D8%A7%D9%84%D8%B7%D8%A7%D9%82%D8%A9%20%D8%A7%D9%84%D9%85%D8%AA%D8%AC%D8%AF%D8%AF%D8%A9!5e0!3m2!1sen!2seg!4v1649481388052!5m2!1sen!2seg" width={700} height={500} style={{ border: 0 }} allowFullScreen loading="lazy" />
                    </div>
                  </div>
                  <div className='border border-primary rounded-lg p-6 w-full flex flex-col gap-6 group'>
                    <Text text={contactData[0].addresses[1]} link="https://maps.app.goo.gl/ythiSmWK5wBK9ZJP9" />
                    <div className='w-full h-[300px]  '>
                    <iframe className='w-full h-full rounded-[10px] ' src="https://www.google.com/maps/embed?pb=!1m17!1m12!1m3!1d3455.2988541605314!2d30.908831999999997!3d29.999574!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m2!1m1!2zMjnCsDU5JzU4LjUiTiAzMMKwNTQnMzEuOCJF!5e0!3m2!1sen!2seg!4v1715264526193!5m2!1sen!2seg" width={700} height={500} style={{ border: 0 }} allowFullScreen loading="lazy" />
                    </div>
                  </div>
                </>
              )
            }
        </section>




      </div>
    </>
  )
}
const Text = ({ text, link }) => {
  return (
    <Link target='_blank' to={link} className='   flex items-center my-1 group-hover:text-primary '>
      <div className='bg-primary p-3 rounded-full w-14 h-14 flex items-center justify-center'>
        <svg width="31" height="30" viewBox="0 0 31 30" fill="none" xmlns="http://www.w3.org/2000/svg">
          <g clip-path="url(#clip0_103_5233)">
            <path d="M26.9367 0.206289L2.10349 11.4504C-0.761606 12.7623 0.193426 16.885 3.24964 16.885H13.7562V27.1922C13.7562 30.1905 17.9586 31.128 19.2958 28.3166L30.7574 3.95453C31.7124 1.70512 29.229 -0.731211 26.9367 0.206289Z" fill="#B5F2E2" />
          </g>
          <defs>
            <clipPath id="clip0_103_5233">
              <rect width="30.5801" height="30" fill="white" transform="translate(0.386719)" />
            </clipPath>
          </defs>
        </svg>

      </div>
      <h1 className=' text-sm xl:text-2xl font-semibold mx-2'>{text}</h1>
    </Link>
  )
}

export default Section2