import { t } from 'i18next';
import React, { useEffect, useState } from 'react';
import { MdOutlineKeyboardDoubleArrowLeft, MdKeyboardArrowLeft, MdOutlineKeyboardDoubleArrowRight, MdKeyboardArrowRight } from 'react-icons/md';

function MainPagination({ totalPages, onPageChange }) {
  const [currentPage, setCurrentPage] = useState(1);
  const [pageNumbers, setPageNumbers] = useState([]);
  const [showPrevEllipsis, setShowPrevEllipsis] = useState(false);
  const [showNextEllipsis, setShowNextEllipsis] = useState(false);
  const LANG = localStorage.getItem("LANGUAGE") || "ar";

  const handlePageChange = (page) => {
    // window.scrollTo({ top: 0, behavior: "smooth" });
    setTimeout(() => {      
    // Ensure page is within valid range
    const newPage = Math.max(1, Math.min(page, totalPages));
    setCurrentPage(newPage);
    onPageChange(newPage);
    // scroll smoothly to the top of the page
    }, 500);
  };


  const handleStartClick = () => {
    setCurrentPage(1);
    onPageChange(1);
  };

  const handleEndClick = () => {
    
    setCurrentPage(totalPages);
    onPageChange(totalPages);
    
  };

  useEffect(() => {
    const pageNumbers = [];
    for (let i = 1; i <= totalPages; i++) {
      pageNumbers.push(i);
    }
    setPageNumbers(pageNumbers);
  }, [totalPages]);

  useEffect(() => {
    setShowPrevEllipsis(currentPage > 3);
    setShowNextEllipsis(currentPage < totalPages - 2);
  }, [currentPage, totalPages]);

  return (
    <div className={`w-full flex ${LANG === "en" ? "flex-row" : "flex-row-reverse"} justify-center items-center my-5`}>
      <button
        name="prev"
        onClick={() => handlePageChange(currentPage - 1)}
        className={`min-w-[30px] h-[40px] flex justify-center items-center text-[#6C6767] hover:bg-primary hover:text-white text-lg  px-7 font-bold border rounded-2xl mx-2 ${currentPage === 1 ? 'opacity-50 cursor-not-allowed' : ''}`}
        disabled={currentPage === 1}
      >
        {t("السابق")}
      </button>
      {showPrevEllipsis && <span className="text-xl mx-2">...</span>}
      {pageNumbers
        .filter(page => page >= currentPage - 2 && page <= currentPage + 2)
        .map((page) => (
          <button
            key={page}
            className={`w-[30px] h-[30px] flex justify-center items-center p-5 hover:border-primary text-2xl font-bold border  rounded-full mx-2 ${page == currentPage ? ' text-primary  border-primary font-extrabold border-2 ' : 'text-[#6C6767] border-[#6C6767]'}`}
            onClick={() => handlePageChange(page)}
          >
            {page}
          </button>
        ))}
      {showNextEllipsis && <span className="text-xl mx-2">...</span>}
      <button
        name="next"
        onClick={() => handlePageChange(currentPage + 1)}
        className={`min-w-[30px] h-[40px] flex justify-center items-center text-[#6C6767] hover:bg-primary hover:text-white text-lg  px-7 font-bold border rounded-2xl mx-2 ${currentPage === totalPages ? 'opacity-50 cursor-not-allowed' : ''}`}
        disabled={currentPage === totalPages}
      >
        {t("التالي")}
      </button>
    </div>
  );
}

export default MainPagination;
