
import React from 'react'
import { useTranslation } from 'react-i18next'

function Section2({ data }) {

  return (
    <section className='flex flex-col md:flex-row items-center justify-between md:w-[90%] w-[95%] mx-auto relative'>

      <div className='md:w-[50%] flex  flex-col gap-2  '>
        <SectionDiv title={data?.title_1} img={data.image_1 ? data.image_1 : "/assets/AboutUs/Section2/Rectangle 68.png"} text={data.description_1 ? data.description_1 : "نسعي في أن نكون الشركة الأولي في مجال الطاقة المتجددة وأنظمة المنزل الذكي وأنظمة إمدادات الطاقة اللامنقطعة (UPS) وكل ما يخص الإستصلاح الزراعي للأراضي الصحراوية، سواء في التصميم والتركيب والصيانة وتجارة المستلزمات."} />
        <SectionDiv title={data?.title_2} img={data.image_2 ? data.image_2 : "/assets/AboutUs/Section2/Rectangle 69.png"} text={data.description_2 ? data.description_2 : "المساهمة في الحفاظ علي البيئة والحد من إنبعاثات الكربون عن طريق إستبدال أنظمة الطاقة الملوثة للبينة بأنظمة الطاقة المتجددة النظيفة، وتحقيق الإكتفاء الذاتي من إنتشار الزراعة."} />
        <SectionDiv title={data?.title_3} img={data.image_3 ? data.image_3 : "/assets/AboutUs/Section2/Rectangle 68.png"} text={data.description_3 ? data.description_3 : "نسعى في تطوير النشاط وزيادة حجم الأعمال سنويا في جميع المجالات التي تعمل عليها الشركة، كما نسعي في نشر الثقافة والوعي بكل ما يخص أنظمة الطاقة المتجددة وأنظمة المنزل الذكي وأنظمة إمدادات الطاقه اللامنقطعة UPS وكل ما يخص الإستصلاح الزراعي للأراضي الصحراوية من خلال العروض الفنية ودراسات الجدوي الإقتصادية التي تقدم مجانا لكل من يطلبها."} />
      </div>


      <div className='w-full md:w-[45%]  md:h-[437px] h-[300px] mt-10'>
        <img data-aoss="fade-up" data-aoss-delay="400" className=' w-full h-full object-cover   rounded-xl   mx-auto' src="/assets/home/section4/p1.jpeg" alt="" />
      </div>
    </section>
  )
}
const SectionDiv = ({ title, title2, text, img }) => {
  const { t } = useTranslation()

  return (
    <>
      <section className={`w-full  flex flex-row group justify-between items-center `}>
        <div className='w-full flex flex-col '>
          <h1 className='text-primary flex font-bold text-4xl my-5  items-end gap-2'>
            <span className='bg-primary w-7  h-7 rounded-lg drop-shadow-md'></span>
            {t(title)}
            <p className='text-secondary duration-300 group-hover:text-primary px-2'>{t(title2)}</p>
          </h1>
          <p className=' md:text-xl  w-[100%]' dangerouslySetInnerHTML={{ __html: text }}></p>
        </div>
      </section>
    </>
  )
}
export default Section2